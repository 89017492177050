import {VendorPaymentMethodI} from "../vendor/vendorInterface";
import Cookies from "universal-cookie";
import aes from 'crypto-js/aes';
import enc from 'crypto-js/enc-utf8'

export function getPaymentIconById(paymentTypeId: string): string {
    let icons = {
        "1" : "/images/payment/paypal.png",
        "2" : "/images/payment/credit_card.png",
        "4" : "/images/payment/credit_card.png",
        "5" : "/images/payment/Cash_icon.png",
        "6" : "/images/payment/room_charge.png",
        "7" : "/images/payment/apple_pay.png",
        "8" : "/images/payment/credit_card.png",
        "9" : "/images/payment/credit_card.png",
        "10" : "/images/payment/credit_card.png",
        "28" : "/images/payment/credit_card.png",
    };
    if(paymentTypeId in icons){
        // @ts-ignore
        return icons[paymentTypeId]
    }else{
        return "/images/payment/Cash_icon.png"
    }
}


export function getOrderStatusColor(status: string):string {
    let colors = {
        "New": "orange",
        "In Process": "blue",
        "Completed": "green",
        "Canceled": "red",
    }
    if(status in colors){
        // @ts-ignore
        return colors[status]
    }else{
        return ""
    }

}

export function getOrderStatusLabel(status: string):string {
    let labels = {
        "New": "Sending",
        "In Process": "In Process",
        "Completed": "Completed",
        "Canceled": "Failed, Contact to waiter",
    }
    if(status in labels){
        // @ts-ignore
        return labels[status]
    }else{
        return ""
    }
}

export function getPaymentTypeById(methods: Array<VendorPaymentMethodI>, methodId: string): VendorPaymentMethodI | undefined {
    return methods.find(m => m.PaymentTypeID === methodId);
}


export interface OrderCookieI{
    orderId?: string,
    timeSet?: string
}

export function setOrderCookie(orderId: string) {
    const cookies = new Cookies();
    let expDate = new Date();
    let data = {
        orderId: orderId,
        timeSet: expDate.getTime().toString()
    }
    expDate.setTime(expDate.getTime() + 7200000);
    let dataJson = JSON.stringify(data);
    let dataEnc = aes.encrypt(dataJson, 'nina').toString()
    cookies.set('pending_order_id',dataEnc,{
        expires: expDate
    } )
}

export function getOrderCookie(): OrderCookieI {
    const cookies = new Cookies();
    const data = cookies.get('pending_order_id');
    if(!data){return {}}
    let res =  aes.decrypt(data, 'nina').toString(enc);
    return JSON.parse(res);
}

export function clearOrderCookie() {
    const cookies = new Cookies();
    cookies.remove('pending_order_id');
}
