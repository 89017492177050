import {useAppSelector} from "../../../../../app/hooks";
import {selectItemSelected} from "../../../productSlice";
import {ProductVariationGroup} from "./ProductVariationGroup";
import {v4 as uuid} from "uuid";
import {ProductVariationGroupRadio} from "./ProductVariationGroupRadio";
import {CartItem} from "../../../../cart/CartItem";

interface ProductVariationsInterface {
    cartItem: CartItem,
    setCartItem: any
}
export function ProductVariations({cartItem, setCartItem} : ProductVariationsInterface) {
    const itemSelected = useAppSelector(selectItemSelected);

    return (
        <div>
            {
                itemSelected?.Variations.map(group => {
                    if(group.Multiple === "0") {
                        return <ProductVariationGroupRadio
                            key={group.ItemVariationID}
                            group={group}
                            cartItem={cartItem}
                            setCartItem={setCartItem}
                        />
                    }

                    return <ProductVariationGroup
                        key={uuid()}
                        group={group}
                        cartItem={cartItem}
                        setCartItem={setCartItem}
                    />
                })
            }
        </div>
    )
}
