import {MenuItemExtraDetailsInterface, MenuItemInterface, ProductViewInterface} from "./menuInterface";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../app/store";
import {MenuItem} from "./MenuItem";

interface ProductState {
    itemSelected: MenuItemInterface| null,
    productView: ProductViewInterface,
}

const initialState: ProductState = {
    itemSelected: null,
    productView: {
        mode: 'product',
        open: false
    },
}

export const productSlice = createSlice({
    name: 'productSlice',
    initialState,
    reducers:{
        setItemSelected: (state, action: PayloadAction<MenuItemInterface | null>) => {
            state.itemSelected = action.payload;
        },
        setProductView: (state, action: PayloadAction<ProductViewInterface>) => {
            state.productView = action.payload;
        },
        setItemSelectedExtraDetails: (
            state,
            action: PayloadAction<MenuItemExtraDetailsInterface>
        ) => {
            if(state.itemSelected){
                state.itemSelected.Gallery = action.payload.gallery;
                state.itemSelected.Allergens = action.payload.allergens;
                state.itemSelected.Variations = action.payload.variations;
            }
        },
    }
})

export const {setItemSelected, setProductView, setItemSelectedExtraDetails} = productSlice.actions;
export default productSlice.reducer;
export const selectProductView = (state: RootState) => state.product.productView;
export const selectItemSelected = (state: RootState) => {
    if(state.product.itemSelected){
        return new MenuItem(state.product.itemSelected);
    }else{
        return null;
    }
};
