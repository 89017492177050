import {Hero, HeroAvatar, HeroImage} from "../../../styled_components/Hero";
import CONFIG from "../../../app/config";
import React, {useEffect, useState} from "react";
import {Collapsible, CollapsibleHead, TextP, Title1} from "../../../styled_components/General";
import {HiChevronDown} from "react-icons/hi";
import {useAppSelector} from "../../../app/hooks";
import {selectEstablishment} from "../establishmentSlice";
import {MultiVendorCategory} from "./MultiVendorCategory";
import {EstablishmentCategoryInterface, QrOutletInterface} from "../vendorInterface";
import {v4 as uuid} from "uuid";
import {VendorInfoLine} from "../../../styled_components/Vendor";
import {FiGlobe, FiMapPin, FiPhoneCall} from "react-icons/fi";

export function MultiVendor() {
    const [expanded, setExpanded] = useState(false);
    const establishment = useAppSelector(selectEstablishment);
    const [categories, setCategories] = useState<Array<EstablishmentCategoryInterface>>([]);
    const collapseToggle = () => {
        setExpanded(!expanded);
    }

    useEffect(() => {
        if (establishment.data) {
            let cats: Array<EstablishmentCategoryInterface> = [];
            establishment.data.Outlets.forEach((outlet: QrOutletInterface) => {
                const i = cats.findIndex(cat => {
                    return cat.name === outlet.TypeName
                })
                if(i >= 0){
                    cats[i].Outlets.push(outlet);
                }else{
                    cats.push({
                        name: outlet.TypeName,
                        Outlets: [outlet]
                    })
                }
            })
            setCategories(cats);
        }
    },[establishment])

    return (
        <div className="multi-outlets-screen">
            <Hero>
                <HeroImage>
                    <img src={CONFIG.COVERS_URL + establishment.data?.CoverImage} alt="cover"/>
                </HeroImage>
                <HeroAvatar src={CONFIG.LOGOS_URL + establishment.data?.LogoFileName}/>
            </Hero>
            <div className="pt-8"></div>
            <TextP weight="300" className="indent mt-2">{establishment.data?.TypeName}</TextP>
            <div className="indent">
                <CollapsibleHead>
                    <Title1 className="mt-2 flex-grow" onClick={collapseToggle}>
                        {establishment.data?.Name}
                    </Title1>
                    <div className="icon" onClick={collapseToggle}>
                        <HiChevronDown color="var(--theme-modded-fore-color)"
                                       size={25} className={`transition-all ${expanded ? "rotate-180" : ""}`}/>
                    </div>

                </CollapsibleHead>
            </div>
            <div className="indent" >
                <Collapsible className={expanded ? "expanded" : ""}>
                    <div className="p-2 pt-4 pb-3">
                        {establishment.data?.Address &&
                            <VendorInfoLine>
                                <FiMapPin className="inline-block mr-3"/>
                                {establishment.data?.Address}

                            </VendorInfoLine>
                        }

                        {establishment.data?.PhoneNumber &&
                            <VendorInfoLine>
                                <FiPhoneCall className="inline-block mr-3"/>
                                <a target="_blank" rel="noreferrer"
                                   href={"tel:" + establishment.data.PhoneNumber}>{establishment.data.PhoneNumber}</a>
                            </VendorInfoLine>
                        }

                        {establishment.data?.URL &&
                            <VendorInfoLine>
                                <FiGlobe className="inline-block mr-3"/>
                                <a target="_blank" rel="noreferrer" href={"https://" + establishment.data.URL}>
                                    {establishment.data.URL}
                                </a>
                            </VendorInfoLine>
                        }
                    </div>
                </Collapsible>
            </div>

            <div className="mt-8">
                {categories.map(category => {
                    return <MultiVendorCategory key={uuid()} category={category}/>
                })}
            </div>
        </div>
    )
}
