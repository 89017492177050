import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {selectVendor, setVendorOpenStatus} from "../vendorSlice";
import {Pill, PillDot} from "../../../styled_components/Pill";
import React, {useEffect} from "react";
import {getVendorStatus} from "../vendorRepository";

export function VendorOpenStatusPill(){
    const vendor = useAppSelector(selectVendor);
    const dispatch = useAppDispatch()



    useEffect(() => {
        getVendorStatus(vendor.OutletID).then(res => {
            dispatch(setVendorOpenStatus(res))
        })

        const refreshStatus = setInterval(()=> {
            getVendorStatus(vendor.OutletID).then(res => {
                dispatch(setVendorOpenStatus(res))
            })
        }, 60000);

        return () => {
            clearInterval(refreshStatus);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return (
        <Pill color={vendor.shopOpenStatus().Color}>
            <PillDot color={vendor.shopOpenStatus().Color}></PillDot>
            {vendor.shopOpenStatus().Description}
        </Pill>
    )
}
