import {ProductVariationPrice, ProductVariationQuantity} from "../../../../../styled_components/Product";
import {MdCheck} from "react-icons/md";
import {TextP} from "../../../../../styled_components/General";
import {MenuItemVariationOptionInterface} from "../../../menuInterface";
import {useAppSelector} from "../../../../../app/hooks";
import {selectVendor} from "../../../../vendor/vendorSlice";
import {canShowVariationPrices} from "../../../menuService";
import {formatCurrency} from "../../../../core/utils/format";

interface ProductVariationSingleInterface {
    roundBoxDesign?: boolean,
    option: MenuItemVariationOptionInterface,
    checked: boolean,
    addOption: any
}

export function ProductVariationSingle({roundBoxDesign, option, addOption, checked}: ProductVariationSingleInterface) {
    const vendor = useAppSelector(selectVendor);
    const showPrices = canShowVariationPrices({
        price: option.price, vendorVisibility: vendor.VisibilityOption
    })

    return (
        <div className="flex justify-between items-center mb-4" onClick={() => addOption(option)}>
            <div className="flex items-center" style={{height: '30px'}}>
                <ProductVariationQuantity populated={checked} round={roundBoxDesign}>
                    <span>
                       <MdCheck size={18}/>
                    </span>
                </ProductVariationQuantity>

                <TextP color="var(--theme-modded-fore-color)">{option.Name}</TextP>
            </div>
            {
                showPrices &&
                <ProductVariationPrice active={checked}>
                    {formatCurrency(option.price)}
                </ProductVariationPrice>
            }
        </div>
    );
}
