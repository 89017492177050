import {configureStore, ThunkAction, Action, combineReducers} from '@reduxjs/toolkit';
import vendorReducer from '../features/vendor/vendorSlice';
import intlReducer from '../features/intl/intlSlice';
import themeReducer from '../features/theme/themeSlice';
import menuReducer from '../features/menu/menuSlice';
import productReducer from '../features/menu/productSlice';
import cartReducer from '../features/cart/cartSlice';
import ordersReducer from '../features/cart/ordersSlice';
import panelsReducer from "../features/core/panelsSlice";
import establishmentReducer from '../features/vendor/establishmentSlice'
import {loadState} from "./browser-storage";
import sessionReducer from "../features/session/sessionSlice";
import userReducer from "../features/user/userSlice"

const rootReducer = combineReducers({
  vendor: vendorReducer,
  menu: menuReducer,
  product: productReducer,
  intl: intlReducer,
  theme: themeReducer,
  session: sessionReducer,
  establishment: establishmentReducer,
  cart: cartReducer,
  user: userReducer,
  purchases: ordersReducer,
  panels: panelsReducer
})


export const store = configureStore({
  reducer: rootReducer,
  preloadedState: loadState(),
  middleware: (getDefaultMiddleware => getDefaultMiddleware({
    serializableCheck: false
  })),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
