import {Gateway} from "../Gateway";

/**
 * JccHandler
 *
 * @class JccHandler
 * @extends {Gateway}
 */
export class JccHandler extends Gateway{

    submit = ()  => {
        this.getPaymentRequestID().then(id => {
            this.props.navigate(`/jccPayment/${id}/${this.accountId}/${encodeURIComponent(this.orderTotal)}`)
        })
    }
}
