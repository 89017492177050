import {
    QrDetailsInterface, EstablishmentInterface,
    TableInfoInterface,
    VendorButtonInterface,
    VendorInterface,
    VendorScheduleInterface, VendorPaymentMethodI, VendorCompanyI, VendorOpenStatusI
} from "./vendorInterface";
import axios from "axios";
import CONFIG from "../../app/config";

export function getQrDetails(refId: string) {
    return new Promise <QrDetailsInterface>((resolve, reject) => {
        axios.get(`${CONFIG.API_URL}/qr/details?refId=${refId}`)
            .then(res => {
                if(res.status === 200 && res.data) {
                    const details :QrDetailsInterface = res.data
                    resolve(details);
                }else{
                    reject(res.status)
                }
            }).catch(e => reject(e))
    })
}

export function getQrOutlets(refId: string) {
    return new Promise <EstablishmentInterface>((resolve, reject) => {
        axios.get(`${CONFIG.API_URL}/qr/outlets?refId=${refId}`)
            .then(res => {
                if(res.status === 200 && res.data) {
                    const details :EstablishmentInterface = res.data
                    resolve(details);
                }else{
                    reject(res.status)
                }
            }).catch(e => reject(e))
    })
}

export function getTableDetails(id: string) {
    return new Promise <TableInfoInterface>((resolve, reject) => {
        axios.get(`${CONFIG.API_URL}/outlet/table?tableId=${id}`)
            .then(res => {
                if(res.status === 200 && res.data) {
                    const details :TableInfoInterface = res.data
                    resolve(details);
                }else{
                    reject(res.status)
                }
            }).catch(e => reject(e))
    })
}

export function getVendor(id: string) {
    return new Promise <VendorInterface>((resolve, reject) => {
        axios.get(`${CONFIG.API_URL}/outlet?id=${id}`)
            .then(res => {
                if(res.status === 200) {
                    const vendor :VendorInterface = res.data[0]
                    resolve(vendor);
                }else{
                    reject(res.status)
                }
            }).catch(e => reject(e))
    })
}

export function getVendorStatus(id: string){
    return new Promise <VendorOpenStatusI>((resolve, reject) => {
        axios.get(`${CONFIG.API_URL}/outlet/status?id=${id}`)
            .then(res => {
                if(res.status === 200) {
                    const data :VendorOpenStatusI = res.data
                    resolve(data);
                }else{
                    reject(res.status)
                }
            }).catch(e => reject(e))
    })
}

export function getVendorCompany(outletId: string, userId: string) {
    return new Promise <VendorCompanyI>((resolve, reject) => {
        axios.get(`${CONFIG.API_URL}/outlet/company?userId=${userId}&outletId=${outletId}`)
            .then(res => {
                if(res.status === 200) {
                    const outletCompany :VendorCompanyI = res.data
                    resolve(outletCompany);
                }else{
                    reject(res.status)
                }
            }).catch(e => reject(e))
    })
}

export function getVendorButtons(id: string) {
    return new Promise<Array<VendorButtonInterface>>((resolve, reject) => {
        axios.get(`${CONFIG.API_URL}/outlet/buttons?id=${id}`)
            .then(res => {
                if(res.status === 200) {
                    const buttons :Array<VendorButtonInterface> = res.data
                    resolve(buttons);
                }else{
                    reject(res.status)
                }
            }).catch(e => reject(e))
    })
}

export function getVendorSchedule(id: string) {
    return new Promise<VendorScheduleInterface>((resolve, reject) => {
        axios.get(`${CONFIG.API_URL}/outlet/schedule?id=${id}`)
            .then(res => {
                if(res.status === 200) {
                    const schedule :VendorScheduleInterface = res.data
                    resolve(schedule);
                }else{
                    reject(res.status)
                }
            }).catch(e => reject(e))
    })
}

export function getVendorPaymentMethods(id: string) {
    return new Promise<Array<VendorPaymentMethodI>>((resolve, reject) => {
        axios.get(`${CONFIG.API_URL}/outlet/payment_methods?id=${id}`)
            .then(res => {
                if(res.status === 200) {
                    const methods :Array<VendorPaymentMethodI> = res.data
                    resolve(methods);
                }else{
                    resolve([]);
                }
            }).catch(e => resolve([]))
    })
}
