import {ShopMapUrlInterface, ShopOpenStatusInterface, VendorInterface} from "./vendorInterface";

export interface Vendor extends VendorInterface {};
export class Vendor {
    constructor(vendor: VendorInterface) {
        Object.assign(this, vendor)
    }

    shopOpenStatus(): ShopOpenStatusInterface {
       let status: ShopOpenStatusInterface = {
           Color: 'green',
           Description: 'Open'
       }
        if(this.OpenStatus?.ClosingSoon){
            status.Color = 'orange';
            status.Description = 'Closing Soon'
        }
        if(!this.OpenStatus?.Status){
            status.Color = 'red';
            status.Description = 'Closed'
        }
       return status;
    }

    getShopMapUrl(): ShopMapUrlInterface {
        let result: ShopMapUrlInterface = {
            isSet: false,
            url: ""
        };
        if(this.GoogleLatitudeCoord && this.GoogleLatitudeCoord.length){
            result.isSet= true;
            result.url = `https://www.google.com/maps/place/${this.GoogleLatitudeCoord}${this.GoogleLongitudeCoord}`;

        }
        return result;
    }
}
