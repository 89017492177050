import {
    MenuCategoryInterface,
    MenuItemInterface
} from "./menuInterface";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../app/store";
import {MenuItem} from "./MenuItem";

interface MenuState {
    items: Array<MenuCategoryInterface>,
    itemList: Array<MenuItemInterface>,
    favourites: Array<MenuItem>,
}

const initialState: MenuState = {
    items: [],
    itemList: [],
    favourites: [],
}

export const menuSlice = createSlice({
    name: 'menuSlice',
    initialState,
    reducers: {
        setMenu: (state, action: PayloadAction<Array<MenuCategoryInterface>>) => {
            state.items = action.payload
        },
        setMenuList: (state, action: PayloadAction<Array<MenuItemInterface>>) => {
            state.itemList = action.payload
        },

        setFavourites: (state, action: PayloadAction<Array<MenuItem>>) => {
            state.favourites = action.payload;
        }
    }
})

export const {setMenu, setMenuList,
    setFavourites} = menuSlice.actions;
export default menuSlice.reducer;
export const selectMenu = (state: RootState) => state.menu.items;
export const selectFavourites = (state: RootState) => state.menu.favourites;
export const selectMenuItemsList = (state: RootState) => state.menu.itemList;

