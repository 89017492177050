import styled, {css} from "styled-components";

export const Indented = styled.div`
  padding: 0 var(--theme-walls-indentation);
`;


interface TextProps {
    weight?: string;
    color?: string;
}

export const TextP = styled.p<TextProps>`
  font-size: var(--theme-fz-text);
  line-height: 18px;
  color: ${props => props.color ? props.color : `var(--theme-grey-color)`};
  font-weight: ${props => props.weight ? props.weight : `normal`};
`;

export const Title1 = styled.h1<TextProps>`
  font-size: var(--theme-fz-title-1);
  font-weight: 500;
  color: ${props => props.color ? props.color : `var(--theme-modded-fore-color)`};
`;

export const Title2 = styled.h2<TextProps>`
  font-size: var(--theme-fz-title-2);
  font-weight: 500;
  color: ${props => props.color ? props.color : `var(--theme-modded-fore-color)`};
`;

export const Collapsible = styled.div`
  overflow: hidden;
  box-shadow: var(--theme-shadow);
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
  max-height: 0;
  border-radius: 5px;

  &.expanded {
    max-height: 10000px;
    transition: max-height 1s ease-in-out;
  }

  &.no-shadow{
    box-shadow: none;
  }
`;

export const CollapsibleHead = styled.div`
  display: flex;
  align-items: center;

  .icon {
    width: 25px
  }
`;

interface LocaleButtonInterface {
    isActive?: boolean
}

export const LocaleButton = styled.img<LocaleButtonInterface>`
  width: 40px;
  aspect-ratio: 1;
  border-radius: 50%;
  ${props => props.isActive && css`
    border: solid 2px var(--theme-primary-color);
  `};
`;

interface RoundButtonSmallInterface {
    diameter?: string
}
export const RoundButtonSmall = styled.div<RoundButtonSmallInterface>`
  width: ${props => props.diameter ? props.diameter : '45px'};
  aspect-ratio: 1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0,0,0,0.5);
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255,255,255,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  .spinner-box{
    width: 150px;
    height: 150px;
    background: rgba(0,0,0,0.5);
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const ListItemCard = styled.div`
  background: #fcfeff;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;
