import {MenuCategoryInterface, MenuSubcategoryInterface} from "../../menuInterface";
import * as Scroll from "react-scroll";
import {Element} from "react-scroll";
import {StickyMenuHeaderSubCategory} from "../../../../styled_components/Menu";
import {generateSubcategoryId} from "../../menuService";

interface StickyMenuSubcategoryContainerInterface{
    subcategory: MenuSubcategoryInterface,
    isActive: boolean,
    category: MenuCategoryInterface
}

export function StickyMenuSubcategoryContainer({subcategory, isActive, category}: StickyMenuSubcategoryContainerInterface) {
    const scroller = Scroll.scroller;
    const identifier = generateSubcategoryId(category.Id, subcategory);

    const showCategory = () => {
        scroller.scrollTo((identifier), {
            smooth: false,
            offset: -210,
            ignoreCancelEvents: true,
        });
    }

    return (
        <Element name={'sticky-' + identifier}>
            <StickyMenuHeaderSubCategory
                onClick={showCategory}
                id={'sticky-' + identifier}
                isActive={isActive}>
                {subcategory.Name}
            </StickyMenuHeaderSubCategory>
        </Element>

    )
}
