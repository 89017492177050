import CONFIG from "../../app/config";
import {TableInfoInterface, VendorInterface, VendorOpenStatusI, VendorOperation} from "../vendor/vendorInterface";
import {isProductCategoryAvailableInScheduleI, MenuItemInterface, MenuSubcategoryInterface} from "./menuInterface";
import {UserInterface} from "../user/userInterface";

export function canShowAddToCart({vendorOperationId, vendorOpenStatus, table}: {
    vendorOperationId?: String,
    vendorOpenStatus?: VendorOpenStatusI
    table?: TableInfoInterface
    
}) {
    return (
        table?.TypeName !== "Menu" &&
        vendorOpenStatus?.Status  &&
        vendorOperationId?.toString() === VendorOperation.DigitalOrdering 
        &&CONFIG.ENABLE_CART
    )
    
}

export function canShowVariationPrices({price, vendorVisibility}: {
    price: String,
    vendorVisibility?: String
}) {
    return (
        price && price !== "0"
        // vendorVisibility === VendorVisibility.MenuWithPrices

    )
}

export function canShowProductKitchenNotes({vendor}: {
    vendor: VendorInterface,
}) {
    return (vendor.OperationID === VendorOperation.DigitalOrdering)

}

export function canShowMenuItemPrice({price}: {
    price?: String
}) {
    return (
        price &&
        price !== "0" &&
        price !== "-"
    )
}

export function canShowCartButton({cartItemsLength, table}: {
    cartItemsLength: number,
    table?: TableInfoInterface
}) {
    return (
        table?.TypeName !== "Menu" &&
        cartItemsLength > 0 &&
        CONFIG.ENABLE_CART
    )
}

export function canUserOrder({user, vendor}: {
    user: UserInterface,
    vendor: VendorInterface,
}) {
    return (
        canGuestUserOrder({vendor}) ||
        user.RegisteredUserID !== CONFIG.GUEST_USER_ID ||
        user.Waiter
    )
}

export function canGuestUserOrder({vendor}: {
    vendor: VendorInterface,
}) {
    return (
        CONFIG.ENABLE_GUEST_CHECKOUT &&
        vendor.AllowGuestUsers === "1"
    )
}

export function canOrderAgain({vendor}: {
    vendor: VendorInterface,
}) {
    return (
        vendor.OperationID === VendorOperation.DigitalOrdering &&
            vendor.OpenStatus?.Status
    )
}

export function isProductCategoryAvailableInSchedule({product}: {
    product: MenuItemInterface
}): isProductCategoryAvailableInScheduleI {

    if (product.menucategoryDetails.length > 0) {
        let res = product.menucategoryDetails.find(mc => mc.CurrentlyAvailable === "1");
        // console.log("menucatergories details: "+ res);
        if (res) {
            return {
                isAvailable: true
            };
        } else {
            return {
                isAvailable: false,
                availabilityDetailsMsg: 'Served Between: ' + product.StartTime?.slice(0, -3) + ' - ' + product.EndTime?.slice(0, -3)
            };
        }
    }
    return {
        isAvailable: true
    };
}

export function canShowTableInfo({table}: {
    table?: TableInfoInterface
}) {
    return (
        table &&
        table?.TypeName !== "Menu"
    )
}

export function generateSubcategoryId(
    categoryId: string,
    subcategory: MenuSubcategoryInterface,
    prepend?: string
): string {
    let result = prepend ?? "";
    result += categoryId + ('-subcategory-' + subcategory.Id);
    return result;
}
