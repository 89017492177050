import {useAppSelector} from "../../../../app/hooks";
import {selectSession} from "../../../session/sessionSlice";
import CONFIG from "../../../../app/config";
import {Navigate} from "react-router-dom";
import React from "react";
import {CheckoutScreen} from "./CheckoutScreen";

export function CheckoutScreenGuard() {

    const session = useAppSelector(selectSession);
    return (
        <>
            {!CONFIG.ENABLE_CART &&
                <Navigate to="/menu" />
            }
            {session.rcn &&
                <Navigate to='/menu' />
            }
            <CheckoutScreen />
        </>
    )
}
