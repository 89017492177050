import {QuantitySwitchButton, QuantitySwitchContainer, QuantitySwitchNumber} from "../../../styled_components/Core";
import {IoIosAdd} from "react-icons/io";
import {HiMinusSm} from "react-icons/hi";

interface QuantitySwitchInterface {
    add: any,
    deduct: any,
    quantity: number,
    maxQ?: number,
    minQ?: number
}

export function QuantitySwitch({add, deduct, quantity, maxQ, minQ}: QuantitySwitchInterface) {

    const addQty = () => {
        if(maxQ){
            if(quantity < maxQ){
                add(1);
            }
        }else{
            add(1)
        }
    }

    return (
        <QuantitySwitchContainer>
            <QuantitySwitchButton outline onClick={() => deduct(1)}>
                <HiMinusSm/>
            </QuantitySwitchButton>
            <QuantitySwitchNumber>
                {quantity < 10 && '0'}
                {quantity}
            </QuantitySwitchNumber>
            <QuantitySwitchButton onClick={addQty} disabled={quantity >= (maxQ ?? 10)}>
                <IoIosAdd size={20}/>
            </QuantitySwitchButton>
        </QuantitySwitchContainer>
    );
}
