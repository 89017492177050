import {expireSession} from "./sessionSlice";
import { setMenu} from "../menu/menuSlice";
import {setItemSelected} from "../menu/productSlice";
import {unsetVendor} from "../vendor/vendorSlice";
import {unsetEstablishment} from "../vendor/establishmentSlice";
import {clearCart} from "../cart/cartSlice";

export function SessionHardReset(dispatch: any) {
    localStorage.removeItem('intent');
    dispatch(expireSession());
    dispatch(setItemSelected(null));
    dispatch(unsetVendor);
    dispatch(unsetEstablishment());
    dispatch(setMenu([]));
    dispatch(clearCart());
}
