import Cookies from "universal-cookie";
import aes from 'crypto-js/aes';
import enc from 'crypto-js/enc-utf8'

export function getUserOTPValidationStatus(phone: string) {
    const cookies = new Cookies();
    const status = cookies.get('session_otp_v');
    if(!status){return false}
    const dc = aes.decrypt(status, phone ).toString(enc);
    return dc === 'valid';
}

export function setUserOTPValidationStatus(phone: string) {
    let bytes = aes.encrypt('valid', phone).toString();
    const cookies = new Cookies();
    let expDate = new Date();
    expDate.setFullYear(expDate.getFullYear() + 1);
    cookies.set('session_otp_v', bytes, {
        expires: expDate
    });
}
