import {EstablishmentInterface} from "./vendorInterface";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../app/store";


interface EstablishmentState {
    data?: EstablishmentInterface,
}

const initialState: EstablishmentState = {}

export const establishmentSlice = createSlice({
    name: "establishmentSlice",
    initialState,
    reducers: {
        setEstablishment: (state, action: PayloadAction<EstablishmentInterface>) => {
            state.data = action.payload;
        },
        unsetEstablishment: state => {
            state.data = undefined;
        }

    }
});
export default establishmentSlice.reducer;
export const {setEstablishment, unsetEstablishment} = establishmentSlice.actions;
export const selectEstablishment = (state: RootState) => state.establishment;
