import styled from "styled-components";

interface PillProps {
    color?: string;
    background?: string;
}
interface PillDotProps {
    color?: string;
}

export const Pill = styled.div<PillProps>`
  background-color: ${props => props.background ? props.background : `white`};
  color: ${props => props.color ? props.color : `var(--theme-grey-color)`};
  max-height: 30px;
  padding: 8px 15px;
  border-radius: 15px;
  display: inline-block;
  font-size: 14px;
  margin: 0 3px;
`;

export const PillDot = styled.div<PillDotProps>`
  display: inline-block;
  width: 9px;
  aspect-ratio: 1;
  background-color: ${props => props.color ? props.color : `var(--theme-grey-color)`};
  border-radius: 50%;
  margin-right: 5px;
`;
