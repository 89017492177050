import React, { useLayoutEffect, useState } from 'react';
import {StickyMenuHeaderContainer} from "../../../../styled_components/Menu";
import {ActionButton} from "../../../../styled_components/ActionButton";
import {Hand} from "akar-icons";
import {IoSearchOutline} from "react-icons/io5";
import {TextP, Title2} from "../../../../styled_components/General";
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {selectVendor} from "../../../vendor/vendorSlice";
import {StickyHeaderCategories} from "./StickyHeaderCategories";
import {StickyHeaderSubcategories} from "./StickyHeaderSubcategories";
import {HiMenuAlt2} from "react-icons/hi";
import {CategoriesScreen} from "../CategoriesListScreen/CategoriesScreen";
import CONFIG from "../../../../app/config";
import {setCallPanelStatus, setCategoriesPanelStatus, setSearchPanelStatus} from "../../../core/panelsSlice";

export default function StickyMenuHeader() {
    const [show, setShow] = useState(false);
    const vendor = useAppSelector(selectVendor);
    const [activeCategory, setActiveCategory] = useState("-");
    const dispatch = useAppDispatch();

    useLayoutEffect(() => {
        function updateStatus() {
            if(window.pageYOffset > 200){
                setShow(true);
            }else {
                setShow(false);
            }
        }
        window.addEventListener('scroll', updateStatus);
        updateStatus();
        return () => window.removeEventListener('scroll', updateStatus);
    })

    return (
        <StickyMenuHeaderContainer isActive={show}>
            <div className="flex py-5 px-5 place-content-between items-center">
                <ActionButton onClick={() => dispatch(setSearchPanelStatus(true))}>
                    <IoSearchOutline size={22} fontWeight={300}/>
                </ActionButton>
                <div>
                    <TextP weight="300" className="indent mt-0 text-center">{vendor.TypeName}</TextP>
                    <Title2 className="mt-1 flex-grow text-center" color="var(--theme-modded-fore-color)">
                        {vendor.Name}
                    </Title2>
                </div>
                <div style={{minWidth:"38px"}}>
                    {CONFIG.ENABLE_CALLS &&
                        <ActionButton background="var(--theme-primary-color)" onClick={() => dispatch(setCallPanelStatus(true))}>
                            <Hand color="white" />
                        </ActionButton>
                    }
                </div>
            </div>
            <div className="flex items-center">
                <ActionButton className="ml-2" style={{minWidth: "38px"}} onClick={
                    () => dispatch(setCategoriesPanelStatus(true))
                }>
                    <HiMenuAlt2 />
                </ActionButton>
                <StickyHeaderCategories
                    stickyShown={show}
                    setActiveCategory={setActiveCategory}
                    activeCategory={activeCategory}
                />
            </div>

            <StickyHeaderSubcategories stickyShown={show} activeMainCategory={activeCategory} />
            <CategoriesScreen />
        </StickyMenuHeaderContainer>
    );
}
