import {ActionButton} from "../../../styled_components/ActionButton";
import React, {useEffect, useState} from "react";
import {BsChevronLeft} from "react-icons/bs";
import {TextP, Title1} from "../../../styled_components/General";
import {Translate} from "../../intl/translator";
import {AppButton} from "../../../styled_components/Core";
import {prepareUserLastCart, userCompletedOrders, userFavouriteOutlets, userFavouriteProducts, userLogin, userSendOtp} from "../userRepository";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'
import { parsePhoneNumber } from 'react-phone-number-input'
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {initUser, setUserCompletedOrders, setUserVerificationStatus} from "../userSlice";
import {UserStatus} from "../userInterface";
import {useSessionGuard} from "../../session/hooks";
import {selectVendor, setVendorFavourites} from "../../vendor/vendorSlice";
import {selectMenuItemsList, setFavourites} from "../../menu/menuSlice";
import {lastOrder} from "../../cart/cartRepository";
import {addOrderToPastOrders} from "../../cart/ordersSlice";
import {useNavigate} from "react-router-dom";
import {OtpScreen} from "./OtpScreen";
import {getUserOTPValidationStatus} from "../userService";

export function LoginScreen() {
    useSessionGuard();
    const strings = {
        s_title: Translate('sign_in_title'),
        s_description: Translate('sign_in_description'),
        s_label: Translate('mobile_number'),
        s_button: Translate('next')
    }
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [value, setValue] = useState("");
    const vendor = useAppSelector(selectVendor);
    const menuItemsList = useAppSelector(selectMenuItemsList);
    const [otpOpen, setOtpOpen] = useState(false);
    const [otpTimeout, setOtpTimeout] = useState(0);

    useEffect(() => {
        let otpInterval = setInterval(() => {
            if(otpTimeout > 0) {
                setOtpTimeout(otpTimeout - 1);
            }
        }, 1000)
        return () => {clearInterval(otpInterval)}
    })

    const login = () => {
        let pn = parsePhoneNumber(value);
        if(pn) {
            const phoneCode = "+" + pn.countryCallingCode;
            const phone = pn.nationalNumber;
            userLogin(phone, phoneCode)
                .then(user => {
                    dispatch(initUser(user));

                    if(user.Verified === UserStatus.Verified){
                        Promise.all([
                            userFavouriteProducts(user.RegisteredUserID, vendor.OutletID),
                            userFavouriteOutlets(user.RegisteredUserID),
                            userCompletedOrders(user.RegisteredUserID, vendor.OutletID),
                            lastOrder(user.RegisteredUserID, vendor.OutletID),
                        ]).then(res => {
                            dispatch(setFavourites(res[0]));
                            dispatch(setVendorFavourites(res[1]));
                            dispatch(setUserCompletedOrders(res[2].length));
                            if(res[3]){
                                dispatch(addOrderToPastOrders(prepareUserLastCart(res[3], menuItemsList)))
                            }
                            if(getUserOTPValidationStatus(user.PhoneNum?.toString() ?? phone)){
                                let intent = localStorage.getItem('intent');
                                if(intent){
                                    localStorage.removeItem(intent);
                                    navigate(intent);
                                }else{
                                    navigate('/menu');
                                }
                            }else{
                                dispatch(setUserVerificationStatus("0"));
                                setOtpTimeout(60);
                                userSendOtp(phone, phoneCode).then(() => {
                                    setOtpOpen(true);
                                })
                            }

                        })
                    }else{
                        navigate('/register');
                    }
                }).catch(e => console.log(e))
        }
    }

    return (
        <div id="login-screen">
            <div className="grid py-5 indent items-center content-center grid-cols-2 grid-rows-1 mb-4">
                <div className="col-start-1 col-span-1 row-start-1">
                    <ActionButton onClick={() => {navigate("/menu")}}>
                        <BsChevronLeft size="22"/>
                    </ActionButton>
                </div>
            </div>
            <div className="indent">
                <Title1>{strings.s_title}</Title1>
                <br/>
                <p className="leading-5 text-lg">{strings.s_description}</p>
            </div>
            <div className="form indent mt-8" style={{overflow: "hidden"}}>
                <TextP>{strings.s_label}</TextP>
                <PhoneInput
                    placeholder="Enter phone number"
                    defaultCountry="CY"
                    value={value}
                    onChange={(v) => {
                        if(v) {
                            setValue(v)
                        }
                    }}
                />
            </div>
            <div className="flex justify-center items-center mt-8">
                <AppButton onClick={login}>{otpOpen ? 'loading' : strings.s_button}</AppButton>
            </div>
            <OtpScreen
                open={otpOpen}
                setOpen={setOtpOpen}
                otpTimeout={otpTimeout}
                setOtpTimeout={setOtpTimeout}
            />
        </div>
    )
}
