import {BottomSheet} from "react-spring-bottom-sheet";
import {ActionButton} from "../../../styled_components/ActionButton";
import {BsChevronLeft} from "react-icons/bs";
import React, {useState} from "react";
import {TextP} from "../../../styled_components/General";
import {Translate} from "../../intl/translator";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {selectUser, setUserVerificationStatus} from "../userSlice";
import {userSendOtp, userUpdateData, userVerifyOtp} from "../userRepository";
import {useNavigate} from "react-router-dom";
import {setUserOTPValidationStatus} from "../userService";
import OtpInput from "react18-input-otp";

interface OtpScreenI {
    open: boolean,
    setOpen: any,
    otpTimeout: number,
    setOtpTimeout: any
}

export function OtpScreen({open, setOpen, otpTimeout, setOtpTimeout}: OtpScreenI) {
    const strings = {
        s_title: Translate('otp_screen_title'),
        s_label: Translate('otp_field_label'),
        s_no_code: Translate('otp_field_description'),
        s_resend: Translate('resend')

    }
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const user = useAppSelector(selectUser);
    const [error, setError] = useState(false);
    const [otpValue, setOtpValue] = useState("");


    const resendOtp = () => {
        if (otpTimeout === 0 && user.PhoneNum && user.PhoneCode) {
            userSendOtp(user.PhoneNum, user.PhoneCode).then(() => {
                setOtpTimeout(25);
            })
        }
    }


    const handleOtp = (value: string) => {
        setOtpValue(value);

        if (value.length < 6) {
            return;
        }
        if (user.PhoneNum) {
            userVerifyOtp(user.PhoneNum, value)
                .then((user1) => {
                    setUserOTPValidationStatus(user.PhoneNum?.toString() ?? 'invalid');
                    userUpdateData(user).then(() => {
                        dispatch(setUserVerificationStatus("1"));
                        setOtpValue("");
                        let intent = localStorage.getItem('intent');
                        if (intent) {
                            localStorage.removeItem(intent);
                            navigate(intent);
                        } else {
                            navigate('/menu');
                        }
                    })
                })
                .catch(e => {
                    setError(true);
                    setOtpValue("");
                })
        }
    }

    return (
        <BottomSheet
            open={open}
            onDismiss={() => {
                setOpen(false)
            }}
            defaultSnap={({maxHeight}) => {
                return maxHeight - maxHeight / 9
            }}
            snapPoints={({maxHeight}) => [
                maxHeight - maxHeight / 9,
            ]}
        >
            <div id="otp-screen">
                <div className="grid py-5 indent items-center content-center grid-cols-2 grid-rows-1 mb-4">
                    <div className="col-start-1 col-span-1 row-start-1">
                        <ActionButton onClick={() => {
                            setOpen(false)
                        }}>
                            <BsChevronLeft size="22"/>
                        </ActionButton>
                    </div>

                </div>
                <div className="indent mt-12 text-center">
                    <p className="text-lg">{strings.s_title}</p>
                    <p className="primary-color text-2xl">{user.PhoneCode} {user.PhoneNum}</p>
                </div>
                <div className="text-center mt-8 flex flex-col items-center">
                    <TextP className="text-left px-4">{strings.s_label}</TextP>
                    <OtpInput
                        value={otpValue}
                        numInputs={6}
                        onChange={handleOtp}
                        isInputNum={true}
                        containerStyle={'pincode-input-container'}
                        inputStyle={'input-style'}
                    />
                    {error &&
                        <p className="text-red-600">Invalid Code</p>
                    }
                    <div className="mt-10">
                        {strings.s_no_code}
                        <br/>
                        <p className={otpTimeout === 0 ? 'primary-color pt-2 text-lg' : 'text-gray-700 pt-2 text-lg'}
                           onClick={resendOtp}>
                            {strings.s_resend} OTP {otpTimeout > 0 && '(' + otpTimeout + ')'}
                        </p>

                    </div>
                </div>

            </div>
        </BottomSheet>
    )
}
