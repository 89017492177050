import { initializeApp } from "firebase/app";
import { getMessaging, getToken, Messaging, onMessage} from "firebase/messaging";
import CONFIG from "../../app/config";


const firebaseApp = initializeApp(CONFIG.FIREBASE_CONFIG);
let messaging: Messaging | null = null;

try{
    messaging = getMessaging(firebaseApp);
}catch (e) {
    console.log('Firebase Messaging could not be loaded')
}



export const getFirebaseToken = () => {
    return new Promise<string>((resolve, reject) => {
        if(!messaging){
            reject("No messaging")
        }else{
            getToken(messaging,
                {
                    vapidKey: 'BBvqe5wnQPPNnq1dFAvHmNEz8Ml0OY0DL24lnL8mVtJglq3Y8YI8we7HEMwIbejL3JpzAbSQPQrLi6Jur9ZqJuY'
                }).then(token => {
                resolve(token)
            })
                .catch(e => reject(e))
        }

    })
}

export const firebaseMessageListener = () =>
    new Promise((resolve, reject) => {
        if(!messaging){
            reject("No messaging")
        }else {
            onMessage(messaging, (payload) => {
                resolve(payload);
            });
        }
    });
