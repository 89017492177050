import {CartInterface, CartItemInterface} from "./cartInterfaces";
import {CartItem} from "./CartItem";
import {isEqual} from "lodash";
import {formatCurrency} from "../core/utils/format";

export class Cart{
    Items: Array<CartItemInterface>;

    constructor(cart: CartInterface) {
        this.Items = cart.items;
    }

    itemsCount():number {
        let count = 0;
        this.Items.forEach(item => {
            count += item.Qty ?? 0;
        })
        return count;
    }

    calcCartPrice():number {
        let total = 0;
        this.Items.forEach(item => {
            if(item) {
                const itm = new CartItem(item);
                total += itm.calcPrice();
            }
        })
        return total;
    }

    containsSameItem(item: CartItem): boolean {
        return this.findSameCartItems(item).length > 0;
    }

    findSameCartItems(item: CartItem): Array<CartItemInterface> {
        return this.Items.filter(itm => {
            let cartItem = new CartItem(itm);
            return (
                isEqual(cartItem.Product.MenuItemID, item.Product.MenuItemID) &&
                isEqual(cartItem.Variations, item.Variations) &&
                isEqual(cartItem.Product.CategoryName, item.Product.CategoryName) &&
                isEqual(cartItem.Notes, item.Notes)
            )
        });
    }

    findComplimentaryItems(): Array<CartItemInterface> {
        return this.Items.filter(itm => {
            let cartItem = new CartItem(itm);
            return cartItem.isComplimentary();
        });
    }

    filterComplimentaryItems(): Cart{
        this.Items = this.Items.filter(itm => {
            let cartItem = new CartItem(itm);
            return !cartItem.isComplimentary();
        })
        return this.updatedCartInstance();
    }


    displayFormattedPrice():string {
        return formatCurrency(this.calcCartPrice() / 100);
        // return (this.calcCartPrice() / 100).toFixed(2).toString();
    }

    updatedCartInstance(): Cart {
        let cart: CartInterface = {items: this.Items};
        return new Cart(cart);
    }

}
