import {BottomSheet} from "react-spring-bottom-sheet";
import {CallScreenButton, CallScreenContainer} from "../../../../styled_components/Menu";
import {TextP, Title1} from "../../../../styled_components/General";
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {selectVendorButtons} from "../../../vendor/vendorSlice";
import {selectCallPanelStatus, setCallPanelStatus} from "../../../core/panelsSlice";

export function CallScreen() {
    const availableButtons = useAppSelector(selectVendorButtons);
    const dispatch = useAppDispatch();
    const show = useAppSelector(selectCallPanelStatus);

    const close = () => {
        dispatch(setCallPanelStatus(false));
    }
    return(
        <BottomSheet open={show} onDismiss={close}>
            <CallScreenContainer>
                <Title1>Call Options</Title1>
                <TextP className={"mt-5 mb-7 text-center px-10"}>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. A ab accu at
                </TextP>
                {availableButtons && availableButtons.map((btn: any) => {
                   return <CallScreenButton key={"vendor-btn-"+btn.ItemID} className="mt-3">{btn.Name}</CallScreenButton>
                })}
            </CallScreenContainer>
        </BottomSheet>
    )
}
