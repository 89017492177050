import {Hero, HeroActions, HeroAvatar, HeroImage, HeroPills} from "../../../../styled_components/Hero";
import {ActionButton, ActionButtonLabel} from "../../../../styled_components/ActionButton";
import {BsInfo} from "react-icons/bs";
import {Hand, Heart} from "akar-icons";
import {Pill} from "../../../../styled_components/Pill";
import React from "react";
import CONFIG from "../../../../app/config";
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {selectVendor, selectVendorFavourites, setVendorFavourites} from "../../../vendor/vendorSlice";
import {Translate} from "../../../intl/translator";
import {userFavouriteOutletToggle} from "../../../user/userRepository";
import {selectUser} from "../../../user/userSlice";
import {UserStatus} from "../../../user/userInterface";
import {VendorInterface} from "../../../vendor/vendorInterface";
import {setCallPanelStatus, setSettingsPanelStatus} from "../../../core/panelsSlice";
import {VendorOpenStatusPill} from "../../../vendor/components/VendorOpenStatusPill";
import {useNavigate} from "react-router-dom";
import {selectEstablishment} from "../../../vendor/establishmentSlice";


export function MenuHero() {
    const strings = {
        s_call: Translate('call'),
        s_my_orders: Translate('my_orders')
    }
    const dispatch = useAppDispatch();
    const vendor = useAppSelector(selectVendor);
    const favourites = useAppSelector(selectVendorFavourites);
    const user = useAppSelector(selectUser);
    const establishment = useAppSelector(selectEstablishment);
    const navigate = useNavigate();



    const toggleFavourite = async () => {
        if(isFavourite()){
            let favs = favourites.filter((outlet: VendorInterface) => {
                return outlet.OutletID !== vendor.OutletID
            })
            dispatch(setVendorFavourites(favs));
        }else{
            dispatch(setVendorFavourites([...favourites, vendor]));
        }
        await userFavouriteOutletToggle(user.RegisteredUserID,vendor.OutletID);
    }

    const isFavourite= () => {
        let i = favourites.findIndex((item: VendorInterface) => {
            return item.OutletID === vendor.OutletID
        })
        return i >= 0;
    }

    return(
        <Hero>
            <HeroActions left>
                <ActionButton onClick={() => dispatch(setSettingsPanelStatus(true))}>
                    <BsInfo size="32"/>
                </ActionButton>
                {/*@todo Extract to separate component*/}
                {/*@todo Use a service for a multi-vendor checks*/}
                {(establishment?.data?.Outlets?.length ?? 1) > 1 &&
                    <ActionButton style={{width: 'unset', padding: '0 5px'}}
                                  onClick={() => navigate("/outlets")}>
                        Select Venue
                    </ActionButton>
                }

            </HeroActions>
            <HeroActions right>
                {/*@todo Extract to separate component*/}
                {CONFIG.ENABLE_CALLS &&
                    <ActionButton background={'#fe724c'} labeled={true} onClick={() => dispatch(setCallPanelStatus(true))}>
                        <Hand color="white" />
                        <ActionButtonLabel>{strings.s_call}</ActionButtonLabel>
                    </ActionButton>
                }

                {/*@todo Use a User is-verified service*/}
                {/*@todo Extract to separate component*/}
                {CONFIG.ENABLE_FAVOURITES && user.Verified === UserStatus.Verified &&
                    <ActionButton onClick={toggleFavourite}>
                        <Heart color={isFavourite() ? "red" : "black"} fill={isFavourite() ? "red" : "white"}/>
                    </ActionButton>
                }

            </HeroActions>
            <HeroPills>
                <VendorOpenStatusPill />
                {/*@todo Use a User is-verified service*/}
                {/*@todo Extract to separate component*/}
                {CONFIG.ENABLE_CART && user.Verified === UserStatus.Verified && (user.CompletedOrdersCount ?? 0) > 0 &&
                    <Pill>{strings.s_my_orders}:{user.CompletedOrdersCount}</Pill>
                }
            </HeroPills>
            <HeroImage>
                {/*@todo extract to an image component*/}
                {vendor.CoverImage &&
                    <img src={CONFIG.COVERS_URL + vendor.CoverImage} alt={vendor.Name}/>
                }
            </HeroImage>
            <HeroAvatar src={CONFIG.LOGOS_URL + vendor.FileName} />
        </Hero>
    );
}
