import CONFIG from "../../../app/config";
import {Navigate} from "react-router-dom";
import {useAppSelector} from "../../../app/hooks";
import {selectSession} from "../../session/sessionSlice";
import {CartScreen} from "./CartScreen";
import React from "react";

export function CartScreenGuard() {
    const session = useAppSelector(selectSession);
    return (
        <>
            {!CONFIG.ENABLE_CART &&
                <Navigate to="/menu" />
            }
            {session.rcn &&
                <Navigate to='/menu' />
            }
            <CartScreen />
        </>
    )
}
