import styled from "styled-components";

export const ProductScreenCover = styled.div`
  width: 100%;
  aspect-ratio: 1024/512;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  img{
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
  }
`;

interface ProductStickyHeaderContainerInterface {
    show: boolean
}

export const ProductStickyHeaderContainer = styled.div<ProductStickyHeaderContainerInterface>`
  width: 100%;
  min-height: ${props => props.show ? '70px' : 0};
  max-height: ${props => props.show ? '200px' : 0};
  overflow: hidden;
  background-color: #343434;
  position: absolute;
  z-index: 3;
  border-radius: 17px 17px 0 0;
  opacity: ${props => props.show ? 1 : 0};
  transition: opacity 300ms;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -1px;

`;

export const ProductStickyFooterContainer= styled.div`
  position: absolute;
  width: 100%;
  min-height: 70px;
  overflow: hidden;
  z-index: 3;
  bottom: 0;
  display: flex;
  background-color: var(--theme-modded-sticky-bg);
  align-items: center;
  justify-content: center;
`;

interface ProductAddToCartContainerI{
    disable?: boolean
}
export const ProductAddToCartContainer = styled.div<ProductAddToCartContainerI>`
  background-color: ${props => props.disable ? 'grey' : "var(--theme-primary-color)"};
  height: 54px;
  border-radius: 27px;
  min-width: 230px;
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;


export const ProductScreenPrice = styled.h1`
  color: var(--theme-primary-color);
  font-size: 31px;
  &::first-letter{
    font-size: 20px;
  }
`;

interface ProductVariationQuantityInterface {
    round?: boolean,
    populated?: boolean
}

export const ProductVariationQuantity = styled.div<ProductVariationQuantityInterface>`
  width: 25px;
  aspect-ratio: 1;
  border-radius: ${props => props.round ? '50%' : '3px'};
  border: solid thin #ccc;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  color: white;
  background-color: ${props => props.populated ? 'var(--theme-primary-color)' : 'var(--theme-modded-bg-color)'};
  font-size: 14px;
  transition: background-color 50ms ease-in;

  span {
      //height: ${props => props.populated ? '18px' : 0};
    overflow: hidden;
    transition: all 250ms;
    transform: scale(${props => props.populated ? 1 : 0});
  }
`;

interface ProductVariationPriceInterface {
    active?: boolean
}

export const ProductVariationPrice = styled.div<ProductVariationPriceInterface>`
  color: ${props => props.active ? 'var(--theme-modded-fore-color)' : 'var(--theme-grey-color)'};
  width: 100px;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;
