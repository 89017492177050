import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../app/store";

interface PanelsState {
    search: boolean,
    settings: boolean,
    call: boolean,
    categories: boolean,
    rescanNotification: boolean,
    orderStatusSidebar: boolean
}

const initialState: PanelsState ={
    search: false,
    settings: false,
    call: false,
    categories: false,
    rescanNotification: false,
    orderStatusSidebar: false
}

export const panelsSlice = createSlice({
    name: 'panelsSlice',
    initialState,
    reducers:{
        setSearchPanelStatus: (state, action: PayloadAction<boolean>) => {
            state.search = action.payload;
        },
        setSettingsPanelStatus: (state, action: PayloadAction<boolean>) => {
            state.settings = action.payload;
        },
        setCallPanelStatus: (state, action: PayloadAction<boolean>) => {
            state.call = action.payload;
        },
        setCategoriesPanelStatus: (state, action: PayloadAction<boolean>) => {
            state.categories = action.payload;
        },
        setRescanPanelStatus: (state, action: PayloadAction<boolean>) => {
            state.rescanNotification = action.payload;
        },
        setOrderStatusSidebar: (state, action: PayloadAction<boolean>) => {
            state.orderStatusSidebar = action.payload;
        },
    }
})

export default panelsSlice.reducer;
export const {
    setSearchPanelStatus,
    setSettingsPanelStatus,
    setCallPanelStatus,
    setCategoriesPanelStatus,
    setRescanPanelStatus,
    setOrderStatusSidebar
} = panelsSlice.actions;
export const selectSearchPanelStatus = (state: RootState) => state.panels.search;
export const selectSettingsPanelStatus = (state: RootState) => state.panels.settings;
export const selectCallPanelStatus = (state: RootState) => state.panels.call;
export const selectCategoriesPanelStatus = (state: RootState) => state.panels.categories;
export const selectRescanPanelStatus = (state: RootState) => state.panels.rescanNotification;
export const selectOrderStatusSidebar = (state: RootState) => state.panels.orderStatusSidebar;
