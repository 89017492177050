import {MenuOrderAgainContainer} from "../../../../styled_components/Menu";
import {TextP, Title2} from "../../../../styled_components/General";
// import {ActionButton} from "../../../../styled_components/ActionButton";
// import {BsInfo} from "react-icons/bs";
import React from "react";
import {Translate} from "../../../intl/translator";
import {Cart} from "../../../cart/Cart";
import {CartInterface} from "../../../cart/cartInterfaces";
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {setCart} from "../../../cart/cartSlice";
import {canOrderAgain} from "../../menuService";
import {selectVendor} from "../../../vendor/vendorSlice";


export function MenuOrderAgain({lastCart}: { lastCart: CartInterface }) {
    const strings = {
        s_title: Translate('order_again_box_title'),
        s_description: Translate('order_again_box_description'),
        s_button: Translate('order_again_box_button'),
    }
    const dispatch = useAppDispatch();
    const lastCartModel = new Cart(lastCart).filterComplimentaryItems();
    const vendor = useAppSelector(selectVendor);

    const addToCart = () => {
        let cart: CartInterface = {items: lastCartModel.Items};
        dispatch(setCart(cart));
    }

    return (
        <MenuOrderAgainContainer visible={!!lastCartModel && lastCartModel.Items.length > 0 && canOrderAgain({vendor})}>
            <Title2 color={"var(--theme-modded-fore-color)"}>{strings.s_title}</Title2>
            <TextP color={"var(--theme-modded-fore-color)"}>{strings.s_description}</TextP>
            <div className="order-again-box">
                <div className="flex-1 flex items-left flex-col justify-center">
                    {lastCartModel && lastCartModel.Items[0] &&
                        <p className="text-gray-500 fore-color">
                            {lastCartModel.Items[0].Qty}x {lastCartModel.Items[0].Product.ItemName}
                        </p>
                    }
                    {lastCartModel && lastCartModel.Items[1] &&
                        <p className="text-gray-500 fore-color">
                            {lastCartModel.Items[1].Qty}x {lastCartModel.Items[1].Product.ItemName}
                        </p>
                    }
                    {lastCartModel && lastCartModel.Items.length > 2 &&
                        <p className="text-gray-500 font-bold fore-color">{lastCartModel.Items.length - 2}x more</p>
                    }

                    <p className="font-bold fore-color">
                        <span className="fore-color" >
                            {lastCartModel.displayFormattedPrice()}
                        </span>
                    </p>
                </div>
                <div className="flex items-center">
                    <div className="order-again-box-btn" onClick={addToCart}>
                        {strings.s_button}
                    </div>
                    {/*<ActionButton onClick={() => document.location.replace("/order")}>
                        <BsInfo size="32"/>
                    </ActionButton>*/}
                </div>
            </div>
        </MenuOrderAgainContainer>
    )
}
