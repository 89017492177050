import {MenuCategoryInterface} from "../../menuInterface";
import {useAppSelector} from "../../../../app/hooks";
import {selectMenu} from "../../menuSlice";
import {StickyMenuCategoryContainer} from "./StickyMenuCategoryContainer";
import {v4 as uuid} from "uuid";
import {useEffect, useRef} from "react";
import {scroller} from "react-scroll";
import { useDraggable } from "react-use-draggable-scroll";

interface StickyHeaderCategoriesInterface {
    stickyShown: boolean,
    activeCategory: string,
    setActiveCategory: any
}
export function StickyHeaderCategories({stickyShown, setActiveCategory, activeCategory}: StickyHeaderCategoriesInterface) {
    const menu: Array<MenuCategoryInterface> = useAppSelector(selectMenu);
    const mounted = useRef(false);
    const bottomHeight = window.innerHeight - 265;
    const observerOptions = {
        rootMargin: '-210px 0% -'+bottomHeight+'px 0%',
        threshold: 0
    }

    const dragref =
        useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
    const { events } = useDraggable(dragref, {
        applyRubberBandEffect: true,
    });



    const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if(entry.isIntersecting && entry.target.classList.contains('category-sticky-identifier')) {
                setActiveCategory(entry.target.id);
                scroller.scrollTo('sticky-'+ entry.target.id, {
                    smooth: true,
                    containerId: 'sticky-menu-categories',
                    horizontal: true,
                    offset: -50,
                    duration: 200,
                    ignoreCancelEvents: true,
                });
            }
        })
    }, observerOptions)

    useEffect(() => {
        const container = document.getElementById('sticky-menu-categories')
        if(container && stickyShown) {
            setTimeout(() => {
                container.scrollLeft = 0;
            },300)

        }
    }, [stickyShown])

    useEffect(() => {
        mounted.current = true;
        menu.forEach((category) => {
            let el =  document.getElementById('category-' + category.Id);
            if(el) {
                observer.observe(el);
            }
        })
        return () => {
            mounted.current = false;
            menu.forEach((category) => {
                let el =  document.getElementById('category-' + category.Id);
                if(el) {
                    observer.unobserve(el);
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stickyShown,menu]);

    return (
        <div ref={dragref}
             {...events}

             id="sticky-menu-categories" className="py-2 pr-5 ml-3 overflow-scroll flex no-scrollbar">

            {menu.map(category => {
                if(category.Subcategories.length > 0 && stickyShown){
                    return <StickyMenuCategoryContainer isActive={activeCategory === 'category-' + category.Id} key={uuid()} category={category} />
                }else{
                    return <div key={uuid()}></div>
                }

            })}
        </div>
    );
}
