import {
    MenuItemAllergenInterface,
    MenuItemGalleryInterface,
    MenuItemInterface,
    MenuItemVariationGroupInterface
} from "./menuInterface";

export interface MenuItem extends MenuItemInterface {}
export class MenuItem {

    Gallery: Array<MenuItemGalleryInterface> = [];
    Allergens: Array<MenuItemAllergenInterface> = [];
    Variations: Array<MenuItemVariationGroupInterface> = [];

    constructor(menuItem: MenuItemInterface | null) {
        Object.assign(this, menuItem)
    }


}
