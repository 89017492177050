import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {selectRescanPanelStatus, setRescanPanelStatus} from "../../core/panelsSlice";
import React, {Fragment, useEffect} from "react";
import {Transition, Dialog} from "@headlessui/react";
import {selectLimitedModeStatus} from "../sessionSlice";

export function SessionExpiredNotification() {
    const rescanNotify = useAppSelector(selectRescanPanelStatus);
    const noLimitMode = useAppSelector(selectLimitedModeStatus);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if(!noLimitMode){
            dispatch(setRescanPanelStatus(true));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[noLimitMode])

    const close = () => {
        dispatch(setRescanPanelStatus(false));
    }

    return (
        <Transition show={rescanNotify} as={Fragment}>
            <Dialog open={rescanNotify} onClose={close} className="relative z-50" >

                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/40"/>
                </Transition.Child>

                <div className="fixed inset-0 flex items-center justify-center p-4">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Dialog.Panel className="mx-auto max-w-sm rounded bg-white px-8 py-4">
                            <Dialog.Title className={'text-xl font-medium mb-2'}>QR Timeout</Dialog.Title>
                            <p>
                                Please scan QR code again.
                            </p>
                            <div className={'flex mt-4'}>
                                <button
                                    onClick={close}
                                    className="bg-transparent hover:bg-green-700 text-green-700 font-semibold hover:text-white py-2 px-4 border border-green-700 hover:border-transparent rounded">
                                    Close
                                </button>

                            </div>
                        </Dialog.Panel></Transition.Child>
                </div>

            </Dialog>
        </Transition>
    )
}
//<div onClick={() => dispatch(setRescanPanelStatus(true))}>test</div>
