import React, {useEffect, useState} from "react";
import {Translate} from "../../../intl/translator";
import {CartItem} from "../../../cart/CartItem";

interface ProductCommentsInterface {
    cartItem: CartItem,
    setCartItem: any
}

export function ProductComments({cartItem, setCartItem}: ProductCommentsInterface) {
    const [show, setShow] = useState(false);

    const [notice, setNotice] = useState('');
    const maxLength = 50;

    const strings = {
        s_placeholder: Translate('product_notes_comment')
    }

    useEffect(() => {
        setTimeout(() => {
            setShow(true)
        }, 100)
    })

   const setComments = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const input = e.target.value;
        if (input.length > maxLength) {
            setCartItem(cartItem.setNotes(input.substring(0, maxLength)));
            setNotice(`You have reached the maximum character limit of ${maxLength}.`);
        } else {
            setCartItem(cartItem.setNotes(input));
            setNotice('');
        }
    }


    return (
        <div className="indent">
            {show && (
                <div className="textarea-container" style={{ position: 'relative' }}>
                    <textarea
                        onChange={setComments}
                        autoFocus={false}
                        className="
                            block
                            w-full
                            px-3
                            py-1.5
                            text-base
                            font-normal
                            text-gray-700
                            bg-clip-padding
                            border border-solid border-gray-300
                            rounded
                            transition
                            ease-in-out
                            mt-10
                            card-modded-bg
                            focus:text-gray-700 focus:bg-white focus:border-black focus:outline-none
                        "
                        rows={4}
                        placeholder={strings.s_placeholder}
                        defaultValue={cartItem.Notes}
                        value={cartItem.Notes}
                    />
                    <div className="notice" style={{ color: 'red', fontSize: '0.875rem', position: 'absolute', bottom: '-1.5rem', left: '0' }}>
                        {notice}
                    </div>
                </div>
            )}
        </div>
    );
}
