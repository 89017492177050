import axios from "axios";
import CONFIG from "../../app/config";
import {Order} from "./Order";
import {NinaOrderI} from "./cartInterfaces";

export interface CreateOrderResponseI{
    OrderID: string,
    Status: string
}

export interface CreatePaymentRequestOrderResponseI{
    PaymentRequestID: string,
    Status: string
}
export function createOrder(order: Order, locale: string) {
    return new Promise<CreateOrderResponseI>((resolve, reject) => {
        axios.post(`${CONFIG.API_URL}/cart/order`, {
            order: order.formattedOrder(),
            locale
        })
            .then(res => {
                const response: CreateOrderResponseI = res.data[0];
                resolve(response);
            })
            .catch(e => reject(e))
    })
}
export function createRequest(order: Order, locale: string, total: string) {
    return new Promise<CreateOrderResponseI>((resolve, reject) => {
        axios.post(`${CONFIG.API_URL}/cart/order/request`, {
            order: order.formattedOrder(),
            total: total,
            Creatfrom: "webapp",
            locale
        })
            .then(res => {
               
                const response: CreateOrderResponseI = res.data;
                // console.log("test here: "+response);
                resolve(response);
            })
            .catch(e => reject(e))
    })
}


export const navigateToTips = async (request: String) => {
    
    const payload = {
        RequestID: request
        
    };
    
    // Set the cookie with domain attribute
    document.cookie = `requestID=${request}; domain=.ninatech.co; path=/`;
    document.cookie = `payload=${encodeURIComponent(JSON.stringify(payload))}; domain=.ninatech.co; path=/`;

    // Redirect to the tips URL
    window.location.href = `${CONFIG.TIPS_URL}`;
};
export function createPaymentRequestOrder(order: Order, locale: string) {
    return new Promise<CreatePaymentRequestOrderResponseI>((resolve, reject) => {
        axios.post(`${CONFIG.API_URL}/payments/request`, {
            order: order.formattedOrder(),
            locale
        })
            .then(res => {
                const response: CreatePaymentRequestOrderResponseI = res.data;
                resolve(response);
            })
            .catch(e => console.log(e))
    })
}

export function lastOrder(userId: string, outletId: string) {
    return new Promise<NinaOrderI>((resolve, reject) => {
        axios.get(`${CONFIG.API_URL}/cart/order/last?userId=${userId}&outletId=${outletId}`)
            .then(res => {
                resolve(res.data[0])
            })
            .catch(e => console.log(e))
    })
}


export function loadOrderPerPaymentRequestId(prId: string, localeId: string) {
    return new Promise<NinaOrderI>((resolve, reject) => {
        axios.get(`${CONFIG.API_URL}/cart/order/payments?ptypeid=${prId}&localeId=${localeId}`)
            .then(res => {
                // console.log("data here "+res.data[0].OrderID)
                resolve(res.data[0])
            })
            .catch(e => console.log(e))
    })
}

export function loadOrder(orderId: string, localeId: string) {
    return new Promise<NinaOrderI>((resolve, reject) => {
        axios.get(`${CONFIG.API_URL}/cart/order?orderId=${orderId}&localeId=${localeId}`)
            .then(res => {
                resolve(res.data[0])
            })
            .catch(e => console.log(e))
    })
}

export function jccRequest(orderId: string, orderTotal: string, jccAccountId: string){
    return new Promise<string>((resolve, reject) => {
        axios.post(`${CONFIG.API_URL}/payments/jcc/request`, {
            orderId,
            orderTotal,
            jccAccountId,
        })
            .then(res => {
                const form: string = res.data;
                resolve(form);
            })
            .catch(e => console.log(e))
    })
}
