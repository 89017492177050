import styled, {css} from "styled-components";

interface TableInfoI {
    size?: number,
    align?: string,
    padding?: string;
}
export const TableInfo = styled.div<TableInfoI>`
  padding: ${props => props.padding ? props.padding : "15px var(--theme-walls-indentation) 10px"};
  color: var(--theme-primary-color);
  font-size: ${props => props.size ? props.size+"px" : "14px"};
  display: flex;
  align-items: ${props => props.align ? props.size : "start"};
  justify-content: ${props => props.align ? props.size : "start"};

  & span {
    font-size: 24px;
    font-weight: bold;
  }
`;

export const SearchField = styled.div`
  height: 45px;
  padding: 0 10px;
  background-color: var(--theme-modded-card-bg);
  margin-left: var(--theme-walls-indentation);
  margin-right: var(--theme-walls-indentation);
  display: flex;
  align-items: center;
  border-radius: 5px;
  color: var(--theme-modded-fore-color);
  //border: solid thin rgba(255, 255, 255, 0.21);
  box-shadow: var(--theme-shadow);

  input {
    background-color: transparent;
    margin-left: 10px;
    font-size: 18px;
    font-weight: 300;
    color: var(--theme-modded-fore-color);
    outline: none;
    width: 100%;
    height: 45px;

    &::placeholder {
      font-size: 16px;
      color: var(--theme-modded-fore-color);
      opacity: 0.5;
    }
  }
`;

export const MenuCategoryItems = styled.div`
  margin-right: var(--theme-walls-indentation);
  margin-left: calc(var(--theme-walls-indentation) / 2);
  border-left: solid 2px var(--theme-primary-color);
`;

export const MenuItemContainer = styled.div`
  margin-top: 5px;
  min-height: 110px;
  padding: 10px 0 10px 20px;
  display: flex;
  background-color: var(--theme-modded-card-bg);
  border-radius: 5px;
  box-shadow: var(--theme-shadow);
  margin-left: 10px;
  &.inOrder {
    border-left: solid 10px var(--theme-primary-color);
    padding-left: 10px;
    border-radius: 0 5px 5px 0;
    margin-left: 0;
  }
`;

export const MenuItemTitle = styled.div`
  font-size: 14pt;
  font-weight: 500;
  color: ${props => props.color ? props.color : `var(--theme-modded-fore-color)`};
`

export const MenuItemDescription = styled.div`
  font-size: 11pt;
  line-height: 16px;
  color: var(--theme-grey-color);
  font-weight: 400;
`

export const MenuItemDetails = styled.div`
  flex-grow: 1;
  padding-right: 5px;
`;

export const MenuItemQuantity = styled.div`
  display: inline-flex;
  background-color: var(--theme-primary-color);
  border-radius: 50%;
  width: 18px;
  aspect-ratio: 1;
  color: white;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
`;

export const MenuItemImage = styled.div`
  min-width: 116px;
  max-width: 116px;
  height: 95px;
  background: #f4f4f4;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  img{
    margin-right: 2px;
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
  }
`;

export const MenuItemPrice = styled.div`
  display: inline-block;
  color: var(--theme-primary-color);
  font-size: 16px;
  font-weight: 500;
`;

export const MenuItemTag = styled.div`
  display: inline-flex;
  background-color: var(--theme-primary-color);
  color: white;
  padding: 3px 5px;
  border-radius: 6px;
  margin-right: 10px;
  font-size: var(--theme-fz-text);
`;

interface StickyMenuHeaderContainerInterface {
    isActive: boolean;
}

export const StickyMenuHeaderContainer = styled.div<StickyMenuHeaderContainerInterface>`
  //min-height: 200px;
  max-height: 200px;
  overflow: hidden;
  position: fixed;
  top: -200px;
  width: 100vw;
  z-index: 1;
  background-color: var(--theme-modded-bg-color);
  transition: top 350ms ease-in;
  
  ${props => props.isActive && css`
    top: 0;
    box-shadow: var(--theme-shadow);
  `};
`;

interface StickyMenuHeaderCategoryInterface {
    isActive?: boolean;
    isDisabled?: boolean;
}

export const StickyMenuHeaderCategory = styled.div<StickyMenuHeaderCategoryInterface>`
  border-radius: 5px;
  padding: 10px 15px;
  margin-right: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #f4f4f4;
  white-space: nowrap;
  user-select: none;
  ${props => props.isActive && css`
    background-color: var(--theme-primary-color);
    color: white;
  `};
  ${props => props.isDisabled && css`
    //background-color: #f4f4f4;
    color: #abaaaa;
  `};
`;


interface StickyMenuHeaderSubCategoryInterface {
    isActive?: boolean;
    isDisabled?: boolean;
}

export const StickyMenuHeaderSubCategory = styled.div<StickyMenuHeaderSubCategoryInterface>`
  border-radius: 18px;
  padding: 10px 15px;
  margin-right: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #f4f4f4;
  white-space: nowrap;
  user-select: none;
  ${props => props.isActive && css`
    background-color: var(--theme-primary-color);
    color: white;
  `};
  ${props => props.isDisabled && css`
    background-color: #f4f4f4;
    color: #abaaaa;
  `};
`;

interface SideScreenContainerInterface {
    isActive: boolean;
    side: "left" | "right";
}

export const SideScreenContainer = styled.div<SideScreenContainerInterface>`
  z-index: 4;
  width: 100vw;
  height: 100vh;
  padding-bottom: calc(100vh - ${window.innerHeight}px);
  background-color: var(--theme-modded-bg-color);
  position: fixed;
  ${props => props.side}: -100vw;
  transition: ${props => props.side} 250ms ease-in-out;
  ${props => props.isActive && css`${props.side}: 0;`};
  overflow: auto;
`;


export const CallScreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

interface CallScreenButtonInterface {
    inverted?: boolean;
}

export const CallScreenButton = styled.div<CallScreenButtonInterface>`
  width: 317px;
  border-radius: 28.5px;
  height: 57px;
  background-color: ${props => props.inverted ? `white` : `var(--theme-primary-color)`};
  border: solid thin ${props => props.inverted ? `var(--theme-primary-color)` : `white`};
  color: ${props => props.inverted ? `var(--theme-primary-color)` : `white`};
  display: flex;
  align-items: center;
  justify-content: center;
`;


export const CategoriesScreenCategoryItem = styled.div`
  padding: 15px;
  margin-top: 25px;
  margin-bottom: 5px;
  background-color: rgba(0,0,0,0.1);
  color: var(--theme-modded-fore-color);
  span{
    float: right;
  }
`;


export const CategoriesScreenSubcategoryItem = styled.div`
  color: var(--theme-primary-color);
  padding: 15px 20px;
  border-left: solid 2px var(--theme-primary-color);
  margin-left: 10px;
`;

interface MenuStickyFooterContainerI {
    visible?: boolean
}
export const MenuStickyFooterContainer = styled.div<MenuStickyFooterContainerI>`
  position: fixed;
  width: 100%;
  min-height: 70px;
  overflow: hidden;
  z-index: 3;
  bottom: ${props => props.visible ? '0' : '-1000px'};
  display: flex;
  background-color: var(--theme-modded-sticky-bg);
  align-items: center;
  justify-content: center;
  transition: all 500ms;
  
  
  @media only screen and (min-width: 700px) {
    max-width: 450px;

}

`;

export const FooterCartButton = styled.div`
  background-color: var(--theme-primary-color);
  height: 54px;
  border-radius: 27px;
  min-width: 80%;
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  .count{
    width: 30px;
    aspect-ratio: 1;
    border-radius: 50%;
    background-color: white;
    color: black;
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

interface MenuOrderAgainContainerI {
    visible?: boolean
}
export const MenuOrderAgainContainer = styled.div<MenuOrderAgainContainerI>`
  padding-left: var(--theme-walls-indentation);
  padding-right: var(--theme-walls-indentation);
  margin-bottom: 20px;
  display: ${props => props.visible ? 'block' : 'none'};
  .order-again-box{
    display: flex;
    padding: 8px;
    background-color: var(--theme-modded-card-bg);
    min-height: 100px;
    border-radius: 5px;
    margin-top: 10px;
    box-shadow: var(--theme-shadow);
    .order-again-box-btn{
      background: var(--theme-primary-color);
      color: white;
      padding: 10px;
      border-radius: 5px;
      margin: 5px;
    }
  }
`
