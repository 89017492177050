import {MenuItemGalleryInterface} from "../../menuInterface";
import { Swiper, SwiperSlide } from 'swiper/react';
import {Navigation} from "swiper";
import {v4 as uuid} from "uuid";
import CONFIG from "../../../../app/config";
import 'swiper/css';
import 'swiper/css/navigation';
import {RoundButtonSmall} from "../../../../styled_components/General";
import {AiOutlineClose} from "react-icons/ai";
import {ProductScreenCover} from "../../../../styled_components/Product";
import Config from "../../../../app/config";


interface ProductScreenHeroInterface {
    gallery: Array<MenuItemGalleryInterface>,
    cover?: string,
    close: any
}
export function ProductScreenHero({cover, gallery, close}: ProductScreenHeroInterface) {

    return (
        <div style={{position: 'relative', borderRadius: '16px 16px 0 0'}}>
            <RoundButtonSmall onClick={close}
                              style={{position: 'absolute', top: "5px", right: "5px", zIndex: 2}}>
                <AiOutlineClose color="white" size={20}/>
            </RoundButtonSmall>
            {cover && gallery.length === 0 &&
                <ProductScreenCover>
                    <img src={Config.MENU_IMAGES_URL + cover} alt=""/>
                </ProductScreenCover>
            }

            {gallery.length > 0 &&
                <>
                    <Swiper modules={[Navigation]} navigation>
                        {gallery.map(image => {
                            return <SwiperSlide key={uuid()}>
                                <ProductScreenCover>
                                <img src={CONFIG.MENU_ITEM_GALLERY_URL + image.Filename} alt=""/>
                                </ProductScreenCover>
                            </SwiperSlide>
                        })}
                    </Swiper>
                </>

            }
        </div>
    );
}
