import {CartItem} from "../CartItem";
import {MenuItemQuantity} from "../../../styled_components/Menu";
import {setItemSelected, setProductView} from "../../menu/productSlice";
import {useAppDispatch} from "../../../app/hooks";
import {MenuItem} from "../../menu/MenuItem";
import {TextP} from "../../../styled_components/General";

interface CartScreenItemI {
    item: CartItem
}

interface CartVariationDisplayI{
    id: string,
    name: string,
    qty: number,
}

export function CartScreenItem({item}: CartScreenItemI) {
    const dispatch = useAppDispatch();

    const prepareVariations = (): Array<CartVariationDisplayI> => {
        let result: Array<CartVariationDisplayI> = [];
        item.Variations.forEach(variationGroup => {
            variationGroup.items.forEach(variation => {
                const i = result.findIndex(dv => dv.id === variation.OptionID);
                if(i >= 0){
                    result[i].qty++;
                }else{
                    result.push({
                        id: variation.OptionID,
                        name: variation.Name,
                        qty: 1
                    })
                }
            })
        })
        return result;
    }

    const openItem = () => {
        dispatch(setItemSelected(new MenuItem(item.Product)));
        dispatch(setProductView({
            mode: "cart",
            open: true,
            cartItem: item
        }))
    }

    return (
        <div className="mb-4" onClick={openItem}>
            <div className="flex place-content-between items-center content-center">
                <div className="flex items-center">

                    <span className="inline-block text-lg pr-2 fore-color">
                      -  {item.Product.ItemName}
                    </span>
                    <MenuItemQuantity>{item.Qty}</MenuItemQuantity>
                </div>
                <div>
                    <span className="text-lg" style={{
                        color: "var(--theme-primary-color)",
                        paddingLeft: "10px"
                    }}>{item.displayFormattedPrice()}</span>
                </div>
            </div>
            <ul className="list-disc ml-8">
                {
                    prepareVariations().map(variation => {
                        return <CartScreenItemVariation key={variation.id} {...variation} />
                    })
                }
            </ul>
            {item.Notes &&
                <div className="indent mt-1 text-lg">
                    <p className="" style={{color: "var(--theme-primary-color)"}}>Notes:</p>
                    <TextP>{item.Notes}</TextP>
                </div>
            }



        </div>
    )
}

function CartScreenItemVariation(variation: CartVariationDisplayI) {
    return (
        <li style={{color: "grey"}} key={variation.id} className="">
            <span className="text-lg">
                {variation.name}
                {variation.qty > 1 &&
                    <span> (x{variation.qty})</span>
                }
            </span>
        </li>
    )
}
