import {Translate} from "../../../intl/translator";
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {selectLocale} from "../../../intl/intlSlice";
import {selectVendorPaymentMethods} from "../../../vendor/vendorSlice";
import React, {Fragment, useEffect, useState} from "react";
import {NinaOrder} from "../../NinaOrder";
import {loadOrder} from "../../cartRepository";
import {getTableDetails} from "../../../vendor/vendorRepository";
import {selectOrderStatusSidebar, setOrderStatusSidebar} from "../../../core/panelsSlice";
import {SideScreenContainer} from "../../../../styled_components/Menu";
import {ActionButton} from "../../../../styled_components/ActionButton";
import {IoIosClose} from "react-icons/io";
import {getOrderCookie, getOrderStatusColor, getOrderStatusLabel, getPaymentIconById, getPaymentTypeById} from "../../cartService";
import {OrderScreenHeader} from "../../../../styled_components/Cart";
import {AiOutlineLoading3Quarters} from "react-icons/ai";
import {OrderStatusScreenItem} from "./OrderStatusScreenItem";
import {v4 as uuid} from "uuid";
import {TextP, Title1, Title2} from "../../../../styled_components/General";
import {useBackButtonGuard} from "../../../session/hooks";
import {formatCurrency} from "../../../core/utils/format";

export function OrderPendingPreviewPopup() {
    const strings = {
        s_order: Translate('order'),
        s_order_status_outlet_title: Translate('order_status_outlet_title'),
        s_order_status_date_title: Translate('order_status_date_title'),
        s_order_status_orderId_title: Translate('order_status_orderId_title'),
        s_order_status_table_title: Translate('order_status_table_title'),
        s_order_status_items_title: Translate('order_status_items_title'),
        s_order_status_comments_title: Translate('order_status_comments_title'),
        s_order_status_total_title: Translate('order_status_total_title'),
        s_order_status_payment_title: Translate('order_status_payment_title'),
    };
    const initOrder = {
        Status: "new",
        OrderID: "0",
        OutletID: "0",
        AmountTotal: "0",
        Orderitems: []
    }
    const orderCookie = getOrderCookie();
    const locale = useAppSelector(selectLocale);
    const dispatch = useAppDispatch();
    const paymentMethods = useAppSelector(selectVendorPaymentMethods);
    const open = useAppSelector(selectOrderStatusSidebar);
    const [loading, setLoading] = useState(true);
    const [order, setOrder] = useState(new NinaOrder(initOrder));
    const [tableNo, setTableNo] = useState("-")

    useEffect(() => {
        if(orderCookie.orderId && open){
            loadOrderInfo(orderCookie.orderId)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[open, orderCookie.orderId])

    const loadOrderInfo = (orderId: string) => {
        return new Promise((resolve, reject) => {
            loadOrder(orderId , locale.LanguageID).then(res => {
                let orderRes = new NinaOrder(res);
                setOrder(orderRes);
                setLoading(false);
                if(tableNo === "-"){
                    loadTableInfo(res.TableID ?? "0")
                }
                resolve(res);
            }).catch(e => reject(e))
        })
    }

    const loadTableInfo = (tableId: string) => {
        getTableDetails(tableId).then(table => {
            setTableNo(table.TableNo)
        }).catch((e) => setTableNo("0"))
    }

    const close = () => {
        setLoading(true);
        dispatch(setOrderStatusSidebar(false));
    }

    useBackButtonGuard({callback: close, name: "order-popup"})

    return (
        <SideScreenContainer isActive={open} side="right">
            <div className="flex flex-col py-5 px-5">
                <div className="pr-3 absolute">
                    <ActionButton onClick={close}>
                        <IoIosClose size="32"/>
                    </ActionButton>
                </div>
                {!loading &&
                    <>
                        <OrderScreenHeader style={{minHeight: '265px'}}
                            color={getOrderStatusColor(order.info.Status)}>
                            {!loading &&
                                <h2 className="text-center">{strings.s_order} <br/>
                                    {getOrderStatusLabel(order.info.Status)}!
                                </h2>
                            }

                        </OrderScreenHeader>
                        <div className="grow indent pt-2">
                            {!loading &&
                                <div>
                                    <div>
                                        <p style={{color: "grey"}}>{strings.s_order_status_outlet_title}</p>
                                        <p className="text-lg">{order.info.Name}</p>
                                    </div>
                                    <div className="flex place-content-between pt-8 pb-8">
                                        <div>
                                            <p style={{color: "grey"}}>{strings.s_order_status_date_title}</p>
                                            <p className="text-lg">{order.info.Date?.slice(0, -3)}</p>
                                        </div>
                                        <div className="mr-8">
                                            <p style={{color: "grey"}}>{strings.s_order_status_orderId_title}</p>
                                            <p className="text-lg">{order.info.OrderID}</p>
                                        </div>
                                    </div>
                                    <div className="pb-8">
                                        <p style={{color: "grey"}}>{strings.s_order_status_table_title}</p>
                                        <p className="text-lg">#{tableNo}</p>
                                    </div>
                                    <div className="pb-2">
                                        <p style={{color: "grey"}}>{strings.s_order_status_items_title}</p>
                                    </div>
                                </div>
                            }

                            {loading &&
                                <div>
                                    <AiOutlineLoading3Quarters size={50} className="animate-spin m-auto" color="var(--theme-primary-color)"/>
                                </div>
                            }
                            {!loading &&

                                order.info.Orderitems.map(item => {
                                    return <OrderStatusScreenItem key={uuid()} item={item} />
                                })
                            }
                            {!loading &&

                                <div className="pb-2">
                                    {order.info.CustomerGeneralNote !== "-" &&
                                        <div className="mb-4">
                                            <p className="" style={{color: "var(--theme-primary-color)"}}>
                                                {strings.s_order_status_comments_title}:
                                            </p>
                                            <TextP>{order.info.CustomerGeneralNote}</TextP>
                                        </div>
                                    }

                                    <div className="flex place-content-between items-center content-center">
                                        <div>
                                            <Title2 className="inline-block pr-2">
                                                {strings.s_order_status_total_title}:
                                            </Title2>
                                        </div>
                                        <Title1>{formatCurrency(order.info.AmountTotal)}</Title1>
                                    </div>
                                    <hr className="my-3"/>
                                    <div className="flex place-content-between items-center content-center">
                                        <div  style={{width: "200px"}}>
                                            <Title2 className="inline-block pr-2">{strings.s_order_status_payment_title}:</Title2>
                                        </div>
                                        <div className="payment-logo flex justify-end items-center grow">
                                            {getPaymentTypeById(paymentMethods, order.info.PaymentTypeID ?? "5")?.PaymentTypeName}
                                            <img width={49} src={getPaymentIconById(order.info.PaymentTypeID ?? "5")} alt=""/>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </>
                }

            </div>
        </SideScreenContainer>
    )
}
