import {BottomSheet} from "react-spring-bottom-sheet";
import {MenuCategoryInterface} from "../../menuInterface";
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {selectMenu} from "../../menuSlice";
import {ActionButton} from "../../../../styled_components/ActionButton";
import React, {useEffect} from "react";
import {IoIosClose} from "react-icons/io";
import {VendorInterface} from "../../../vendor/vendorInterface";
import {selectVendor} from "../../../vendor/vendorSlice";
import {Title2} from "../../../../styled_components/General";
import {CategoriesScreenCategory} from "./CategoriesScreenCategory";
import {v4 as uuid} from "uuid";
import {selectCategoriesPanelStatus, setCategoriesPanelStatus} from "../../../core/panelsSlice";

export function CategoriesScreen() {
    const menu: Array<MenuCategoryInterface> = useAppSelector(selectMenu);
    const vendor: VendorInterface = useAppSelector(selectVendor);
    const dispatch = useAppDispatch();
    const show = useAppSelector(selectCategoriesPanelStatus);

    useEffect(() => {
        window.history.pushState({}, 'item')
        const handleBack = (e: PopStateEvent) => {
            e.stopPropagation();
            close();
        }
        window.addEventListener('popstate', handleBack);
        return(() => {
            window.removeEventListener('popstate', handleBack);
        })
    })

    const close = () => {
        dispatch(setCategoriesPanelStatus(false))
    }

    return (
        <BottomSheet open={show} onDismiss={close} >
            <div style={{minHeight: 'calc(100vh - 200px)'}}>
                <div className="py-4" style={{position: 'relative'}}>
                    <div className="indent flex items-center">
                        <ActionButton
                            onClick={close}
                            style={{position: 'absolute', left: '10px'}}
                        >
                            <IoIosClose size="32"/>
                        </ActionButton>
                        <div className={'px-8 text-center flex-1 ' + (vendor.Name.length > 20 ? 'pl-16' : '')}>
                            <Title2 color="var(--theme-modded-fore-color)">{vendor.Name}</Title2>
                        </div>
                    </div>
                </div>
                <div className="py-4 mt-4">
                    {menu.map((category) => {
                        return <CategoriesScreenCategory key={uuid()} category={category} />
                    })}
                </div>
            </div>
        </BottomSheet>
    );
}
