import {
    VendorOutletsCategoryBox,
    VendorOutletsOutletItem,
    VendorOutletsOutletItemImage,
    VendorOutletsOutletItemTitle
} from "../../../styled_components/Vendor";
import CONFIG from "../../../app/config";
import {Title2} from "../../../styled_components/General";
import React from "react";
import {EstablishmentCategoryInterface} from "../vendorInterface";
import {useAppSelector} from "../../../app/hooks";
import {selectEstablishment} from "../establishmentSlice";

interface MultiVendorCategoryI {
    category: EstablishmentCategoryInterface
}

export function MultiVendorCategory({category}: MultiVendorCategoryI) {
    const establishment = useAppSelector(selectEstablishment);

    const loadOutlet = (id: string) => {
        localStorage.setItem('load', JSON.stringify({
            id,
            table: establishment?.data?.TableID
        }))
        window.location.replace("/splash");
    }
    return (
        <div>
            <p className="indent text-lg" style={{color: "var(--theme-primary-color)"}}>{category.name}</p>
            <VendorOutletsCategoryBox className="my-4 mx-2">
                {category.Outlets.map(outlet => {
                    return <VendorOutletsOutletItem key={outlet.OutletID} onClick={() => loadOutlet(outlet.OutletID)}>
                        <VendorOutletsOutletItemImage>
                            <img src={CONFIG.COVERS_URL + outlet.CoverImage} alt=""/>
                        </VendorOutletsOutletItemImage>
                        <VendorOutletsOutletItemTitle>
                            <Title2>{outlet.Name}</Title2>
                        </VendorOutletsOutletItemTitle>
                    </VendorOutletsOutletItem>
                })}

            </VendorOutletsCategoryBox>
        </div>
    )
}
