import {CartInterface, CartItemInterface} from "./cartInterfaces";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../app/store";

const initialState: CartInterface = {
    items: [],
    notes: ""
}

export const cartSlice = createSlice({
    name: 'cartSlice',
    initialState,
    reducers: {
        addItemToCart: (state, action: PayloadAction<CartItemInterface>) => {
            state.items = [...state.items, action.payload]
        },
        removeItemFromCart: (state, action: PayloadAction<CartItemInterface>) => {
            let items = [...state.items];
            const i = items.findIndex(item => {
                return item.Id === action.payload.Id;
            })
            if(i >= 0) {
                items.splice(i, 1);
            }
            state.items = [...items];
        },
        updateItemFromCart: (state, action: PayloadAction<CartItemInterface>) => {
            let items = [...state.items];
            const i = items.findIndex(item => {
                return item.Id === action.payload.Id;
            })
            if(i >= 0) {
                items[i] = action.payload;
            }
            state.items = [...items];
        },
        setCart:(state, action: PayloadAction<CartInterface>) => {
            state.items = [...action.payload.items];
        },
        setCartNotes:(state, action: PayloadAction<string>) => {
            state.notes = action.payload;
        },
        clearCart: state => {
            state.items = [];
            state.notes = "";
        }
    }
})

export default cartSlice.reducer;
export const {addItemToCart, removeItemFromCart,updateItemFromCart, clearCart, setCart, setCartNotes} = cartSlice.actions;
export const selectCart = (state: RootState) => state.cart
export const selectCartComments = (state: RootState) => state.cart.notes;
