import {ActionButton} from "../../../styled_components/ActionButton";
import {BsChevronLeft} from "react-icons/bs";
import React, {useEffect, useState} from "react";
import {Translate} from "../../intl/translator";
import {TextP, Title1} from "../../../styled_components/General";
import {AppButton, AppInputField} from "../../../styled_components/Core";
import 'react-phone-number-input/style.css'
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {selectUser, setUserFormData} from "../userSlice";
import {SubmitHandler, useForm} from "react-hook-form";
import {UserInterface} from "../userInterface";
import {OtpScreen} from "./OtpScreen";
import {userSendOtp} from "../userRepository";
import {useSessionGuard} from "../../session/hooks";
import {useNavigate} from "react-router-dom";

interface Inputs {
    name: string,
    email: string,
    phone: string
}

export function RegisterScreen() {
    useSessionGuard();
    const strings = {
        s_title: Translate('register_title'),
        s_description: Translate('register_description'),
        s_button: Translate('get_otp_button'),
        s_name_label: Translate('full_name'),
        s_email_label: Translate('email_address_from_field'),
        s_phone_label: Translate('mobile_number'),
    }
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const user = useAppSelector(selectUser);
    const {register, handleSubmit, formState: {errors}} = useForm<Inputs>();
    const [otpOpen, setOtpOpen] = useState(false);
    const [otpTimeout, setOtpTimeout] = useState(0);

    useEffect(() => {
        let otpInterval = setInterval(() => {
            if(otpTimeout > 0) {
                setOtpTimeout(otpTimeout - 1);
            }
        }, 1000)
        return () => {clearInterval(otpInterval)}
    })

    const handleNameValidation = (name: string) => {
        let isValid = true;
        let parts = name.split(' ');
        if(parts.length < 2){
            return false;
        }
        parts.forEach(part => {
            if(part.length < 3){
                isValid = false;
            }
        })
        return isValid;
    }

    const onSubmit: SubmitHandler<Inputs> = data => {
        if(otpTimeout > 0) return;
        setOtpTimeout(25)
        let fullNameArray = data.name.split(' ');
        // let pn = parsePhoneNumber(data.phone);
        let userForm: UserInterface = {
            RegisteredUserID: user.RegisteredUserID,
            Verified: user.Verified,
            Name: encodeURIComponent(fullNameArray[0]),
            Surname: encodeURIComponent(fullNameArray[1]),
            Email: data.email,
            PhoneNum: user.PhoneNum,
            PhoneCode: user.PhoneCode,
        }
        if(userForm.PhoneNum && userForm.PhoneCode) {
            dispatch(setUserFormData(userForm));
            userSendOtp(userForm.PhoneNum, userForm.PhoneCode).then(() => {
                setOtpOpen(true);
            })
        }

    };


    return (
        <div id="register-screen">
            <div className="grid py-5 indent items-center content-center grid-cols-2 grid-rows-1 mb-4">
                <div className="col-start-1 col-span-1 row-start-1">
                    <ActionButton onClick={() => {
                        navigate("/login")
                    }}>
                        <BsChevronLeft size="22"/>
                    </ActionButton>
                </div>
            </div>
            <div className="indent">
                <Title1>{strings.s_title}</Title1>
                <br/>
                <p className="leading-5 text-lg">{strings.s_description}</p>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className="form indent mt-8">

                <TextP className="mt-8">{strings.s_phone_label}</TextP>
                <p className="mt-2 mb-4 text-gray-500">{user.PhoneCode} {user.PhoneNum}</p>

                <TextP>{strings.s_name_label}</TextP>
                <AppInputField defaultValue={
                    (user.Name ? user.Name + ' ': '') +
                    (user.Surname ?? '')}
                               {...register("name", {
                    required: true,
                    validate: handleNameValidation
                })}/>
                {errors.name && <span className="text-red-600">Please enter your full name.</span>}


                {/*<Controller
                    control={control}
                    render={({field: {onChange, value}}) => {
                        return <PhoneInput
                            international={false}
                            focusInputOnCountrySelection
                            placeholder="Enter phone number"
                            defaultCountry="CY"
                            value={value}
                            onChange={onChange}
                        />
                    }
                    }
                    name="phone"
                    rules={{required: true}}
                />
                {errors.phone && <span className="text-red-600">Please enter a valid phone number.</span>}*/}

                <TextP className="mt-4">{strings.s_email_label}</TextP>
                <AppInputField
                    defaultValue={user.Email}
                    {
                        ...register("email", {
                            pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        })
                    }
                />
                {errors.email && <span className="text-red-600">Please enter a valid email.</span>}

            </form>
            <div className="flex justify-center items-center mt-8">
                <AppButton onClick={handleSubmit(onSubmit)}>
                    {strings.s_button}
                    {otpTimeout > 0 && ' ('+otpTimeout+')'}
                </AppButton>
            </div>
            <OtpScreen
                open={otpOpen}
                setOpen={setOtpOpen}
                otpTimeout={otpTimeout}
                setOtpTimeout={setOtpTimeout}
            />
        </div>
    )
}
