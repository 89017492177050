import styled from "styled-components";

export const QuantitySwitchContainer = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

interface QuantitySwitchButtonInterface {
    outline?: boolean,
    disabled?: boolean
}
export const QuantitySwitchButton = styled.div<QuantitySwitchButtonInterface>`
  width: 30px;
  aspect-ratio: 1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid thin var(--theme-primary-color);
  background-color: ${props => props.outline ? 'white' : 'var(--theme-primary-color)' };
  ${props => props.disabled && `background-color: grey`};
  color: ${props => props.outline ? 'var(--theme-primary-color)' : 'white' };
`;

export const QuantitySwitchNumber = styled.div`
  width: 40px;
  text-align: center;
  color: var(--theme-modded-fore-color);
`;

export const AppCreditsContainer = styled.div`
  padding-top: 5px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  hr{
    width: 100%;
    margin-bottom: 25px;
  }
  p{
    //color: var(--theme-modded-fore-color);
    color: grey;
  }
  span{
    color: var(--theme-primary-color)
  }
  img{
    width: 130px;
  }
`;

interface AppButtonI{
    disable?: boolean
}

export const AppButton = styled.div<AppButtonI>`
  background-color: ${props => props.disable ? 'grey' : "var(--theme-primary-color)"};
  height: 54px;
  margin-bottom: 15px;
  border-radius: 27px;
  min-width: 230px;
  padding: 10px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
`;


export const AppInputField = styled.input`
  width: 100%;
  border: solid thin black;
  border-radius: 10px;
  height: 65px;
  margin: 5px 0;
  padding-left: 10px;
  font-size: 20px;
`;
