import React from "react";
import {MenuCategoryMemo} from "./MenuCategory";
import {v4 as uuid} from "uuid";
import {MenuCategoryInterface} from "../../menuInterface";

interface MenuResultsInterface {
    items: Array<MenuCategoryInterface>
}
export function MenuResults({items}: MenuResultsInterface) {

    return(
        <div>
            {items.map(category => {
                if(Object.keys(category.Subcategories).length && !category.filtered){
                    return <MenuCategoryMemo
                        key={category.Name}
                        Name={category.Name}
                        Id={category.Id}
                        Subcategories={category.Subcategories}
                        CurrentlyAvailable={category.CurrentlyAvailable}
                    />
                }else{
                    return <div key={uuid()}></div>
                }
            })}
        </div>
    );


}

export const MenuResultsMemo = React.memo(MenuResults);


