import styled from "styled-components";

export const Hero = styled.section`
  height: 285px;
  animation: shimmer 5s infinite linear;
  background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
  background-size: 1000px 100%;
  position: relative;
`;

export const HeroImage = styled.div`
  width: 100%;
  height: 285px;
  aspect-ratio: 390/285;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  img{
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
  }
`;

interface HeroActionsProps {
    left?: boolean;
    right?: boolean;
}

export const HeroActions = styled.div<HeroActionsProps>`
  position: absolute;
  top: 30px;
  width: 180px;
  height: 40px;
  display: flex;
  ${props => props.left && `left: var(--theme-walls-indentation);`}
  ${props => props.right && `right: var(--theme-walls-indentation); justify-content: end;`}
`;

export const HeroAvatar = styled.img`
  position: absolute;
  bottom: -45px;
  right: var(--theme-walls-indentation);
  width: 90px;
  aspect-ratio: 1;
  border: solid 4px white;
  border-radius: 50%;
`;

export const HeroPills = styled.div`
  position: absolute;
  bottom: 10px;
  width: 265px;
  height: 30px;
  //background-color: #7f7f7f50;
  padding-left: var(--theme-walls-indentation);
`;
