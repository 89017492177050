import {UserInterface, UserStatus, WaiterI} from "./userInterface";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../app/store";

const initialState: UserInterface = {
    RegisteredUserID: "113",
    CompletedOrdersCount: 0,
    Verified: UserStatus.UnVerified
}

export const userSlice = createSlice({
    name: 'userSlice',
    initialState,
    reducers: {
        initUser: (state, action: PayloadAction<UserInterface>) => {
            state.RegisteredUserID = action.payload.RegisteredUserID;
            state.PhoneNum = action.payload.PhoneNum;
            state.PhoneCode = action.payload.PhoneCode;
            state.Verified = action.payload.Verified;
            state.Name = action.payload.Name ?? "";
            state.Surname = action.payload.Surname ?? "";
            state.Email = action.payload.Email ?? "";
            state.Waiter = undefined;
        },
        setUserFormData: (state, action: PayloadAction<UserInterface>) => {
            state.PhoneNum = action.payload.PhoneNum;
            state.PhoneCode = action.payload.PhoneCode;
            state.Email = action.payload.Email;
            state.Name = action.payload.Name;
            state.Surname = action.payload.Surname;
            state.Waiter = action.payload.Waiter ?? undefined;
        },
        setUserVerificationStatus: (state, action: PayloadAction<string>) => {
            state.Verified = action.payload;
        },
        setUserCompletedOrders: (state, action: PayloadAction<number>) => {
            state.CompletedOrdersCount = action.payload;
        },
        setUserFirebaseToken: (state, action: PayloadAction<string>) => {
            state.FirebaseToken = action.payload;
        },
        logoutUser: (state) => {
            state.RegisteredUserID = "113";
            state.Verified = UserStatus.UnVerified;
            state.CompletedOrdersCount = 0;
            state.PhoneCode = undefined;
            state.PhoneNum = undefined;
            state.Email = undefined;
            state.Name = undefined;
            state.Surname = undefined;
        },
        loginWaiter: (state, action: PayloadAction<WaiterI>) => {
            state.RegisteredUserID = "113";
            state.Verified = UserStatus.UnVerified;
            state.CompletedOrdersCount = 0;
            state.PhoneCode = undefined;
            state.PhoneNum = undefined;
            state.Email = undefined;
            state.Name = undefined;
            state.Surname = undefined;
            state.Waiter = action.payload
        }
    }
})

export default userSlice.reducer;
export const {initUser, setUserFormData, setUserVerificationStatus,setUserCompletedOrders, setUserFirebaseToken, logoutUser, loginWaiter} = userSlice.actions;
export const selectUser = (state: RootState) => state.user;
