import {FooterCartButton, MenuStickyFooterContainer} from "../../../../styled_components/Menu";
import React, {useEffect, useState} from "react";
import {useAppSelector} from "../../../../app/hooks";
import {selectCart} from "../../../cart/cartSlice";
import {Cart} from "../../../cart/Cart";
import {Translate} from "../../../intl/translator";
import {canShowCartButton} from "../../menuService";
import {selectTable} from "../../../vendor/vendorSlice";
import {useNavigate} from "react-router-dom";

export function MenuStickyFooter() {
    const strings = {
        s_view_order: Translate('view_order')
    }
    const cartSliceState = useAppSelector(selectCart);
    const table = useAppSelector(selectTable);
    const navigate = useNavigate();
    const [cart, setCart] = useState<Cart>(new Cart({items: []}));

    useEffect(() => {
        setCart(new Cart(cartSliceState))
    },[cartSliceState])

    const openCart = () => {
        navigate("/cart");
    }


    return (
        <MenuStickyFooterContainer
            visible={
            canShowCartButton({
                cartItemsLength: cart.itemsCount(),
                table
            })
        }>
            <FooterCartButton onClick={openCart}>
                <div className="flex content-center items-center">
                    <div className="count">{cart.itemsCount()}</div>
                    <p className="text-lg">{strings.s_view_order}</p>
                </div>
                <div>
                    <p className="text-lg">{cart.displayFormattedPrice()}</p>
                </div>
            </FooterCartButton>
        </MenuStickyFooterContainer>
    )
}
