import {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {selectSession, selectPinStatus, setSessionLtdmd, expireSession} from "./sessionSlice";
import {useNavigate} from "react-router-dom";
import {clearCart} from "../cart/cartSlice";

export function useSessionGuard() {
    const session = useAppSelector(selectSession);
    const dispatch = useAppDispatch();
    useEffect(() => {
        const logout = () => {
            dispatch(expireSession());
            dispatch(clearCart());
            setTimeout(() => {
                dispatch(setSessionLtdmd(false))
                // document.location.replace("/menu")
            },100)

        }

        const doChecks = () => {
            if (session.set && session.sxp <= new Date().getTime()) {
                logout();
            } else if (session.sxp === 0) {
                logout();
            }
        }

        doChecks();
        const ping = setInterval(() => {
            doChecks();
        },5000)
        return () => {
            clearInterval(ping)
        }
    },[session, dispatch])
}


export function usePinGuard() {
    const pinStatus = useAppSelector(selectPinStatus);
    const navigate = useNavigate();
    useEffect(() => {
        if(pinStatus.prq && !pinStatus.pst) {
            navigate("/pin");
        }
    },[navigate, pinStatus])
}


interface BackButtonGuardI {
    callback: any,
    name: string,
}
export function useBackButtonGuard({callback, name}: BackButtonGuardI) {
    useEffect(() => {
        window.history.pushState({}, name);
        const backCallback = (e: PopStateEvent) => {
            callback();
        }
        window.addEventListener('popstate', backCallback, {
            capture: true
        });
        return (() => {
            window.removeEventListener('popstate', backCallback);
        })
    })
}
