import React, {useEffect, useState} from 'react';
import {Collapsible, CollapsibleHead, TextP, Title1} from "../../../styled_components/General";
import {HiChevronDown} from "react-icons/hi";
import {BsClock} from "react-icons/bs";
import {VendorInfoLine} from "../../../styled_components/Vendor";
import {FiGlobe, FiMapPin, FiPhoneCall} from "react-icons/fi";
import {useAppSelector} from "../../../app/hooks";
import {selectTable, selectVendor, selectVendorSchedule} from "../vendorSlice";
import {TableInfo} from "../../../styled_components/Menu";
import {Translate} from "../../intl/translator";
import {canShowTableInfo} from "../../menu/menuService";
import {selectLimitedModeStatus} from "../../session/sessionSlice";
import {VendorVisibility} from "../vendorInterface";
import {AiOutlineMail} from "react-icons/ai";
import {selectUser} from "../../user/userSlice";

export function VendorDetails() {
    const strings = {
        s_table: Translate('table')
    }
    const vendor = useAppSelector(selectVendor);
    const vSchedule = useAppSelector(selectVendorSchedule);
    const table = useAppSelector(selectTable);
    const noLimitMode = useAppSelector(selectLimitedModeStatus);
    const user = useAppSelector(selectUser);

    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        if (!noLimitMode && vendor.VisibilityOption === VendorVisibility.OutletInformationOnly) {
            setExpanded(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [noLimitMode])

    const collapseToggle = () => {
        setExpanded(!expanded);
    }

    return (
        <div>
            {canShowTableInfo({table}) &&
            user.Waiter?.Name ? (
                    <TableInfo>
                        Waiter #<span>{table?.TableNo}</span>
                        <span className={"px-2"}>
                            {user.Waiter?.Name} {user.Waiter?.SirName}
                        </span>
                    </TableInfo>
                ) :
                <TableInfo>
                    {strings.s_table} #<span>{table?.TableName}</span>
                </TableInfo>
            }
            <TextP weight="300" className="indent mt-2">{vendor.TypeName}</TextP>
            <div className="indent">
                <CollapsibleHead>
                    <Title1 className="mt-2 flex-grow" onClick={collapseToggle}>
                        {vendor.Name}
                    </Title1>
                    <div className="icon" onClick={collapseToggle}>
                        <HiChevronDown color="var(--theme-modded-fore-color)"
                                       size={25} className={`transition-all ${expanded ? "rotate-180" : ""}`}/>
                    </div>
                </CollapsibleHead>
            </div>
            <div className="indent">
                <Collapsible className={expanded ? "expanded" : ""}>
                    <div className="p-2 pt-4 pb-3">
                        <VendorInfoLine>
                            <BsClock className="inline-block mr-3"/>{vSchedule.working_hours}
                        </VendorInfoLine>
                        {vendor.Address &&
                            <VendorInfoLine>
                              <FiMapPin className="inline-block mr-3"/>
                                {vendor.getShopMapUrl().isSet &&
                                    <a target="_blank"
                                       href={vendor.getShopMapUrl().url} rel="noreferrer">
                                        {vendor.Address}
                                    </a>
                                }
                                {!vendor.getShopMapUrl().isSet && vendor.Address}
                            </VendorInfoLine>
                        }
                        {vendor.PhoneNumber &&
                            <VendorInfoLine>
                              <FiPhoneCall className="inline-block mr-3"/>
                              <a target="_blank" rel="noreferrer"
                                 href={"tel:" + vendor.PhoneNumber}>{vendor.PhoneNumber}</a>
                            </VendorInfoLine>
                        }
                        {vendor.URL &&
                            <VendorInfoLine>
                              <FiGlobe className="inline-block mr-3"/>
                              <a target="_blank" rel="noreferrer" href={"https://" + vendor.URL}>{vendor.URL}</a>
                            </VendorInfoLine>
                        }
                        {vendor.Email &&
                            <VendorInfoLine>
                              <AiOutlineMail className="inline-block mr-3"/>
                              <a target="_blank" rel="noreferrer" href={"mailto:" + vendor.Email}>{vendor.Email}</a>
                            </VendorInfoLine>
                        }
                    </div>
                </Collapsible>
            </div>
        </div>
    );
}
