import {MenuCategoryInterface} from "../../menuInterface";
import {Title2} from "../../../../styled_components/General";
import React from "react";
import {MenuSubcategoryMemo} from "./MenuSubcategory";
import { v4 as uuid } from 'uuid';
import {Element} from "react-scroll";

export function MenuCategory(category: MenuCategoryInterface) {
    return (
        <Element
            name={'category-' + category.Id}

        >
            <Title2 key={uuid()} className="indent" color="var(--theme-modded-fore-color)">{category.Name}</Title2>
            <div
                id={'category-' + category.Id}
                className="category-sticky-identifier"
            >
                {category.Subcategories.map((subcategory) => {
                    if(subcategory.Name === 'None' && subcategory.Items.length && !subcategory.filtered){
                        return <MenuSubcategoryMemo
                            key={uuid()}
                            Name={subcategory.Name}
                            Id={subcategory.Id}
                            Items={subcategory.Items}
                            categoryId={category.Id}
                        />;
                    }else{
                        return <div key={uuid()}></div>
                    }
                })}
                {category.Subcategories.map((subcategory, i) => {
                    if(subcategory.Name !== 'None' && subcategory.Items.length && !subcategory.filtered){
                        return <div key={uuid()}>
                            {i === 0 && <div key={uuid()} className="mt-7"></div>}
                            <MenuSubcategoryMemo
                                key={uuid()}
                                Name={subcategory.Name}
                                Id={subcategory.Id}
                                Items={subcategory.Items}
                                categoryId={category.Id}
                            />
                        </div>;
                    }else{
                        return <div key={uuid()}></div>
                    }
                })}
            </div>
        </Element>
    );
}
export const MenuCategoryMemo = React.memo(MenuCategory);
