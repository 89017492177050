import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {LocaleInterface, selectAvailableLocales, selectLocale, setLocale} from "../../../intl/intlSlice";
import React, {useEffect} from "react";
import {LocaleButton} from "../../../../styled_components/General";
import {getMenu} from "../../menuRepository";
import {selectVendor} from "../../../vendor/vendorSlice";
import {setMenu, setMenuList} from "../../menuSlice";
import {selectUser} from "../../../user/userSlice";
import {isEqual} from "lodash";
import { getTableDetails } from "../../../vendor/vendorRepository";
import { table } from "console";
import {VendorLoadDataInterface, VendorOperation} from "../../../vendor/vendorInterface";

export function LocalePicker() {
    const availableLocales = useAppSelector(selectAvailableLocales, isEqual);
    const activeLocale = useAppSelector(selectLocale, isEqual);
    const vendor = useAppSelector(selectVendor, isEqual);
    const dispatch = useAppDispatch();
    const user = useAppSelector(selectUser, isEqual);
    const data = localStorage.getItem('tableload');
    const state : VendorLoadDataInterface = JSON.parse(data ?? "0");
        const table =  state?.table ?? '0';

      

    useEffect(() => {
        getMenu(vendor.OutletID, activeLocale.LanguageID, user.RegisteredUserID,table).then(menu => {
            dispatch(setMenu(menu.catalog));
            dispatch(setMenuList(menu.list));
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeLocale])

    return (
        <div className="flex py-5 px-5 place-content-evenly">
            {availableLocales.map((locale: LocaleInterface) => {
                return <LocaleButton
                    key={locale.ShortName}
                    isActive={activeLocale.ShortName === locale.ShortName}
                    onClick={() => dispatch(setLocale(locale))}
                    src={`/images/ui/locales/flag_${locale.ShortName}.svg`}
                />
            })}
        </div>
    );
}
