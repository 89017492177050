import {VendorVisibility} from "../vendorInterface";
import {useAppSelector} from "../../../app/hooks";
import {selectVendor} from "../vendorSlice";
import {selectLimitedModeStatus} from "../../session/sessionSlice";
import {useEffect, useState} from "react";

interface LimitedViewGuardProps {
    children: JSX.Element,
    showInVisibilityStatuses: Array<VendorVisibility>
}

// visibility status guard kicks in when the no limit mode status is lifted, e.g. when browser refresh
export function VisibilityStatusGuard({children, showInVisibilityStatuses}: LimitedViewGuardProps) {
    const vendor = useAppSelector(selectVendor);
    const noLimitMode = useAppSelector(selectLimitedModeStatus);
    const [hidden, setHidden] = useState(true);

    useEffect(() => {
        let allowedStatuses: Array<String> = [];
        showInVisibilityStatuses.forEach(status => {
            allowedStatuses.push(status.toString())
        })
        if(noLimitMode){
            setHidden(false);
        }else{
            if(allowedStatuses.includes(vendor.VisibilityOption as String)){
                setHidden(false);
            }else{
                setHidden(true);
            }
        }

    },[noLimitMode, showInVisibilityStatuses, vendor.VisibilityOption])

    return(
        <>
            {!hidden && children}
        </>
    );
}
