
const KEY = "NINA-APP";

export function loadState() {
    try {
        const serializedState = localStorage.getItem(KEY);
        if (!serializedState) return undefined;
        return JSON.parse(serializedState);
    } catch (e) {
        return undefined;
    }
}

export async function saveState(state: any) {
    try {
        const serializedState = JSON.stringify(prepareStateForSaving(state));
        localStorage.setItem(KEY, serializedState);
    } catch (e) {
        // Ignore
    }
}


function prepareStateForSaving(state: any) {
    let persistedState = {
        cart: undefined,
        establishment: undefined,
        intl: undefined,
        menu: undefined,
        product: undefined,
        purchases: undefined,
        theme: undefined,
        user: undefined,
        vendor: undefined,
        session: {
            prq: undefined,
            pst: undefined,
            rcn: undefined,
            set: undefined,
            sst: undefined,
            sxp: undefined,
            nltd: undefined,
        }
    };
    persistedState.cart = {...state.cart};
    persistedState.establishment = {...state.establishment};
    persistedState.intl = {...state.intl};
    persistedState.menu = {...state.menu};
    persistedState.purchases = {...state.purchases};
    persistedState.theme = {...state.theme};
    persistedState.user = {...state.user};
    persistedState.vendor = {...state.vendor};
    persistedState.session = {
        prq: state.session.prq,
        pst: state.session.pst,
        rcn: state.session.rcn,
        set: state.session.set,
        sst: state.session.sst,
        sxp: state.session.sxp,
        nltd: state.session.nltd,
    };
    return persistedState;
}
