import styled from "styled-components";
import {ToastContainer} from "react-toastify";

export const NotificationToast = styled(ToastContainer)`
  &&&.Toastify__toast-container {
    margin: 0 15px;
    width: calc(100vw - 30px);
    &.Toastify__toast-container--bottom-center{
      bottom: 20px;
    }
    .Toastify__toast {
      border-radius: 10px;
      font-size: 20px;
      font-weight: 300;
      margin-bottom: 10px;
      .Toastify__toast-icon{
        display: none;
      }
      svg{
        margin-top: 14px;
        min-width: 30px;
      }
      &.Toastify__toast--success{
        border: 1px solid var(--theme-primary-color);
        box-shadow: 0 1px 5px rgba(248, 175, 175, 0.1);
        color: white;
        background: var(--theme-primary-color);
      }
    }
  }
`;
