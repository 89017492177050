import CONFIG from "../../../../app/config";
import {Navigate} from "react-router-dom";
import React from "react";
import {OrderStatusScreen} from "./OrderStatusScreen";

export function OrderStatusScreenGuard() {
    return (
        <>
            {!CONFIG.ENABLE_CART &&
                <Navigate to="/menu" />
            }
            <OrderStatusScreen />
        </>
    )
}
