import {Translate} from "../../../intl/translator";
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {selectLastOrder} from "../../ordersSlice";
import React, {useEffect, useState} from "react";
import {OrderScreenHeader} from "../../../../styled_components/Cart";
import {AppButton} from "../../../../styled_components/Core";
import {AppCredits} from "../../../core/components/AppCredits";
import {selectCart, setCart} from "../../cartSlice";
import {useNavigate} from "react-router-dom";
import {initSession, setRescanStatus, setSessionLtdmd} from "../../../session/sessionSlice";
import {AiOutlineLoading3Quarters} from "react-icons/ai";

export function OrderPaymentFailed() {
    const strings = {
        s_order: Translate('order')
    };

    const lastOrder = useAppSelector(selectLastOrder);
    const cart = useAppSelector(selectCart);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 1250)
    })
    const loadCart = () => {
        if(cart.items.length > 0 || lastOrder){
            if(cart.items.length <= 0 && lastOrder){
                dispatch(setCart(lastOrder.Cart));
            }
            dispatch(initSession());
            dispatch(setSessionLtdmd(true));
            dispatch(setRescanStatus(false));
            navigate("/cart");
        }
    }

    return (
        <div className="flex flex-col h-screen">
            {!loading &&
                <>
                    <OrderScreenHeader color={'red'}>
                        <h2 className="text-center">{strings.s_order} <br/>
                            Failed
                        </h2>

                    </OrderScreenHeader>
                    <div className="grow indent pt-8">
                        <p className="text-lg text-center">Order failed, <br/>please contact your waiter.</p><br/>
                        <AppButton onClick={loadCart}>Back to Cart</AppButton>
                    </div>
                </>
            }
            {loading &&
                <div className="grow indent pt-8">
                    <AiOutlineLoading3Quarters size={50} className="animate-spin m-auto" color="var(--theme-primary-color)"/>
                </div>
            }
            <AppCredits />
        </div>
    )
}
