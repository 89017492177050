import styled from "styled-components";


export const VendorInfoLine = styled.p`
  font-size: 16px;
  color: var(--theme-grey-color);
  margin-bottom: 10px;
  a{
    color: var(--theme-primary-color);
    text-decoration: underline;
  }
`;

export const VendorOutletsCategoryBox = styled.div`
  margin: 1rem 0.5rem;
  border-left: solid 3px var(--theme-primary-color);
`;


export const VendorOutletsOutletItem = styled.div`
  min-height: 95px;
  box-shadow: var(--theme-shadow);
  margin: 0 10px 15px;
  border-radius: 5px;
  //border: solid thin black;
  display: flex;
`;

export const VendorOutletsOutletItemImage = styled.div`
  min-width: 116px;
  max-width: 116px;
  height: 95px;
  background: #f4f4f4;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  img{
    margin-right: 2px;
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
  }
`;
export const VendorOutletsOutletItemTitle = styled.div`
  min-height: 95px;
  flex-grow: 1;
  display: flex;
  align-items: center;
  padding: 15px;
`;
