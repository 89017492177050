import React, {ChangeEvent, useEffect, useRef, useState} from "react";
import {SearchField, SideScreenContainer} from "../../../../styled_components/Menu";
import {ActionButton} from "../../../../styled_components/ActionButton";
import {IoIosClose} from "react-icons/io";
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {selectMenu} from "../../menuSlice";
import {MenuCategoryInterface, MenuItemInterface, MenuSubcategoryInterface} from "../../menuInterface";
import {MenuResults} from "../MenuScreen/MenuResults";
import {IoSearchOutline} from "react-icons/io5";
import {Translate} from "../../../intl/translator";
import {TextP} from "../../../../styled_components/General";
import {useBackButtonGuard} from "../../../session/hooks";
import {selectSearchPanelStatus, setSearchPanelStatus} from "../../../core/panelsSlice";

// interface MenuSearchScreenInterface {
//     setShow: any,
//     show: boolean
// }

interface SearchState {
    filteredItems: Array<MenuCategoryInterface>,
    searchTerm: string,
    hasResults: boolean
}

export function MenuSearchScreen() {
    const strings = {
        s_description: Translate('search_description'),
        s_no_results_description: Translate('search_description_no_results'),
        s_search_placeholder: Translate('search_field_placeholder'),
    }
    const dispatch = useAppDispatch();
    const menu = useAppSelector(selectMenu);
    const searchPanelStatus = useAppSelector(selectSearchPanelStatus);
    const searchRef = useRef<HTMLInputElement>(null)
    const [searchState, setSearchState] = useState<SearchState>({
        filteredItems: [],
        searchTerm: "",
        hasResults: false
    })

    useEffect(() => {
        if (searchPanelStatus) {
            if(searchRef.current) {
                searchRef.current.focus();
            }
        }
        window.scrollTo(0, 0);
    }, [searchPanelStatus])

    const close = () => {
        dispatch(setSearchPanelStatus(false));
    }
    useBackButtonGuard({callback: close, name: "search-screen" })

    const filterItems = (event: ChangeEvent<HTMLInputElement>) => {
        let searchQ = event.target.value.toLowerCase();
        let filtered: Array<MenuCategoryInterface> = [];
        let resultsFound = false;
        if(searchQ.length > 2){
            filtered = menu.map((category: MenuCategoryInterface) => {
                let cat = {...category, filtered: !category.Name.toLowerCase().includes(searchQ)}
                let subcategories: Array<MenuSubcategoryInterface> = [];
                cat.Subcategories.forEach(subcategory => {
                    let sub = {
                        ...subcategory,
                        filtered: cat.filtered && !subcategory.Name.toLowerCase().includes(searchQ)
                    };
                    let items: Array<MenuItemInterface> = [];
                    sub.Items.forEach(item => {
                        let itm = {...item, filtered: sub.filtered && !item.ItemName.toLowerCase().includes(searchQ)}
                        items.push(itm);
                    })
                    sub.Items = items;
                    sub.filtered = !sub.Items.some(el => !el.filtered);
                    subcategories.push(sub);
                })
                cat.Subcategories = subcategories;
                cat.filtered = !cat.Subcategories.some(el => !el.filtered);
                return cat;
            })
        }
        if(filtered.some(i => !i.filtered)){
            resultsFound = true;
        }
        setSearchState({
            filteredItems: filtered,
            searchTerm: searchQ,
            hasResults: resultsFound
        })

    }

    return (
        <SideScreenContainer isActive={searchPanelStatus} side="right">
            <div className="flex py-5 px-5">
                <div className="pr-3">
                    <ActionButton onClick={close}>
                        <IoIosClose size="32"/>
                    </ActionButton>
                </div>
            </div>
            <SearchField className="mb-4">
                <IoSearchOutline size={22} fontWeight={300}/>
                <input onChange={filterItems} ref={searchRef} type="text"
                       placeholder={strings.s_search_placeholder}
                />
            </SearchField>
            {searchState.searchTerm.length < 3 &&
                <TextP className="indent" style={{fontStyle: 'italic'}}>
                    {strings.s_description}
                </TextP>
            }
            {searchState.searchTerm.length > 2 && !searchState.hasResults &&
                <TextP className="indent" style={{fontStyle: 'italic'}}>
                    {strings.s_no_results_description}
                </TextP>
            }
            <MenuResults items={searchState.filteredItems} />

        </SideScreenContainer>
    );
}
