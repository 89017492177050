import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from "../../app/store";

export interface LocaleInterface {
    LanguageID: string;
    Name: string;
    Description?: string;
    SortOrder?: string;
    ShortName: string;
}

export interface IntlStateInterface {
    locale: LocaleInterface;
    availableLocales: Array<LocaleInterface>
}

const initialState: IntlStateInterface = {
    locale: {
        LanguageID: "2",
        Name: "English",
        ShortName: "En"

    },
    availableLocales: []
}

export const intlSlice = createSlice({
    name: 'intl',
    initialState,
    reducers: {
        setLocale: (state, action: PayloadAction<LocaleInterface>) => {
            state.locale = action.payload;
        },
        setAvailableLocales: (state, action: PayloadAction<Array<LocaleInterface>>) => {
            state.availableLocales = action.payload;
        },
    }
})


export const {setLocale, setAvailableLocales} = intlSlice.actions;
export default intlSlice.reducer;
export const selectLocale = (state: RootState) => state.intl.locale;
export const selectAvailableLocales = (state: RootState) => state.intl.availableLocales;
