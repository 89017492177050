import CONFIG from "../../../app/config";
import {Navigate} from "react-router-dom";
import React from "react";
import {useAppSelector} from "../../../app/hooks";
import {selectLimitedModeStatus, selectSession} from "../../session/sessionSlice";
import {RegisterScreen} from "./RegisterScreen";

export function RegisterScreenGuard() {
    const session = useAppSelector(selectSession);
    const noLimitMode = useAppSelector(selectLimitedModeStatus);
    return (
        <>
            {(!CONFIG.ENABLE_USERS || !noLimitMode || session.rcn) &&
                <Navigate to="/menu" />
            }
            <RegisterScreen />
        </>
    )
}
