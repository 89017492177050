import {StickyMenuHeaderCategory} from "../../../../styled_components/Menu";
import {MenuCategoryInterface} from "../../menuInterface";
import * as Scroll from 'react-scroll';
import {Element} from "react-scroll";

interface StickyMenuCategoryContainerInterface{
    category: MenuCategoryInterface,
    isActive: boolean
}
export function StickyMenuCategoryContainer({category, isActive}: StickyMenuCategoryContainerInterface) {
    const scroller = Scroll.scroller;

    const showCategory = () => {
        scroller.scrollTo('category-'+ category.Id, {
            smooth: false,
            offset: -210,
            ignoreCancelEvents: true,
        });
    }
    return (
        <Element name={'sticky-category-'+ category.Id}
                 id={'sticky-category-'+ category.Id}
        >
            <StickyMenuHeaderCategory
                onClick={showCategory}
                isActive={isActive}
                isDisabled={category.CurrentlyAvailable === "0"}
            >
                {category.Name}
            </StickyMenuHeaderCategory>
        </Element>

    )
}
