export const translations: { [x: string]: { [x: string]: any } } = {
    En: {
        search_field_placeholder: 'Search Menu',
        search_description: 'Type (3) characters or more to start searching.',
        search_description_no_results: 'No results found',
        table: 'Table',
        product_notes_comment: 'Notes',
        cart: 'Cart',
        order_items: 'Order Items',
        order: 'Order',
        order_status_outlet_title: "OUTLET'S NAME",
        order_status_date_title: "ORDER DATE & TIME",
        order_status_orderId_title: "ORDER ID",
        order_status_table_title: "TABLE",
        order_status_items_title: "ITEMS",
        order_status_comments_title: "Order Comments",
        order_status_total_title: "Total Cost",
        order_status_payment_title: "Payment Method",
        cart_screen_description: '',
        cart_screen_comments: 'Comments',
        cart_is_empty: 'Cart is empty',
        checkout: 'Checkout',
        update: 'Update',
        remove: 'Remove',
        orderDefault: 'Your order is in process',
        orderMsg: 'Thank you, Your order has been accepted',
        resend: 'Resend',
        next: 'Next',
        add_to_cart: 'Add to cart',
        view_order: 'View Order',
        call: 'Call',
        my_orders: 'My orders',
        login: 'Login',
        logout: 'Logout',
        sign_in_title: 'Sign In',
        sign_in_description: 'You need to login to proceed.',
        mobile_number: 'Mobile Number',
        full_name: 'Full Name',
        email_address_from_field: 'Email address (optional)',
        register_title: 'Register',
        register_description: 'Please add your details so that we can send you an OTP code to login.',
        get_otp_button: 'Get OTP',
        otp_screen_title: 'Enter the OTP that was sent to',
        otp_field_label: 'OTP code',
        otp_field_description: "Didn't get a code?",
        checkout_screen_description: "Please choose your payment method",
        order_again_box_title: "Order Again",
        order_again_box_description: "Craving to repeat your last order?",
        order_again_box_button: "Order Again",
        service_hours: "Service Hours",
        no_internet_msg: "No Internet Connection.",
        room_payment_sheet_title: "Room Number",
        room_payment_sheet_button: "Submit Order",
        room_payment_sheet_error: "Please enter your room number.",
        account_payment_sheet_title: "Account Charge",
        account_payment_sheet_error: "No Accounts Found!",
        account_payment_sheet_description: "Choose an account:"
    },
    Gr: {
        search_field_placeholder: 'Αναζήτηση',
        table: 'Τραπέζι',
        product_notes_comment: 'Σχόλια',
        orderDefault: 'Η παραγγελία σας βρίσκεται σε εξέλιξη',
        orderMsg: 'Σας ευχαριστούμε η παραγγελία σας έχει γίνει Αποδεκτή',
    },
    Du: {search_field_placeholder: 'Suche'},
    Ru: {search_field_placeholder: 'поиск'}
};
