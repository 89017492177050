import {MenuCategoryInterface} from "../../menuInterface";
import {CategoriesScreenCategoryItem} from "../../../../styled_components/Menu";
import * as Scroll from "react-scroll";
import {BsChevronRight} from "react-icons/bs";
import {CategoriesScreenSubcategories} from "./CategoriesScreenSubcategories";
import {useAppDispatch} from "../../../../app/hooks";
import {setCategoriesPanelStatus} from "../../../core/panelsSlice";

interface CategoriesScreenCategoryInterface {
    category: MenuCategoryInterface,
}

export function CategoriesScreenCategory({category}: CategoriesScreenCategoryInterface) {
    const scroller = Scroll.scroller;
    const dispatch = useAppDispatch();

    const showCategory = () => {
        dispatch(setCategoriesPanelStatus(false));
        scroller.scrollTo(('category-'+category.Id), {
            smooth: false,
            offset: -210,
            ignoreCancelEvents: true,
        });
    }

    return (
        <>
            {category.Subcategories.length > 0 &&
                <>
                    <CategoriesScreenCategoryItem onClick={showCategory}>
                        {category.Name}
                        <span><BsChevronRight/></span>
                    </CategoriesScreenCategoryItem>
                    <CategoriesScreenSubcategories category={category} />
                </>
            }

        </>

    );
}
