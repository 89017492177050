import {MenuItemVariationGroupInterface, MenuItemVariationOptionInterface} from "../../../menuInterface";
import {TextP, Title2} from "../../../../../styled_components/General";
import {ProductVariationSingle} from "./ProductVariationSingle";
import {CartItem} from "../../../../cart/CartItem";
import {useEffect, useState} from "react";

interface ProductVariationGroupInterface {
    group: MenuItemVariationGroupInterface,
    cartItem: CartItem,
    setCartItem: any
}


export function ProductVariationGroupRadio({group, cartItem, setCartItem}: ProductVariationGroupInterface) {

    const [validationError, setValidationError] = useState("");
    useEffect(() => {
        setValidationError("");
        if(cartItem.GroupValidationErrors.length > 0){
            const errors = cartItem.GroupValidationErrors.find(vError => vError.group.ItemVariationID === group.ItemVariationID);
            if(errors){
                setValidationError(errors.msg);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[cartItem])

    if(cartItem.findVariationGroupCartOptions(group).length === 0){
        // const option: MenuItemVariationOptionInterface = group.OptionList[0];
        // setTimeout(() => {
        //     setCartItem(cartItem.selectRadioVariationOfGroup({group, option}));
        // },100)

    }
    const addOption = (option: MenuItemVariationOptionInterface) => {
        setCartItem(cartItem.selectRadioVariationOfGroup({group, option}))
    }

    const isChecked = (option: MenuItemVariationOptionInterface) : boolean => {
        return cartItem.findVariationGroupCartOptions(group).some(o => {
            return o.OptionID === option.OptionID
        })
    }

    return (
        <div id={"group-container-"+group.ItemVariationID}>
            <hr className="indent mb-8"/>
            <Title2 color="var(--theme-modded-fore-color)" className="mb-1">{group.Name}</Title2>
            <TextP>{group.Description}</TextP>
            {validationError &&
                <p className="text-red-600">{validationError}</p>
            }

            <div className="mb-6"></div>
            {group.OptionList.map(option => {
                return <ProductVariationSingle roundBoxDesign key={option.OptionID} option={option} addOption={addOption} checked={isChecked(option)}/>
            })}
        </div>
    );
}
