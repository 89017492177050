import {BottomSheet, BottomSheetRef} from "react-spring-bottom-sheet";
import React, {useRef, useState} from "react";
import {Collapsible} from "../../../../styled_components/General";
import {AppButton} from "../../../../styled_components/Core";
import {useAppSelector} from "../../../../app/hooks";
import {selectVendor} from "../../../vendor/vendorSlice";
import {IoIosClose} from "react-icons/io";
import {ActionButton} from "../../../../styled_components/ActionButton";

export interface PaymentTermsConditionsSheetState{
    open: boolean,
    gateway?: any
}

interface PaymentTermsConditionsI{
    sheetState: PaymentTermsConditionsSheetState,
    setSheetState: any,
    setLoading: any,
    submitOrder: any
}

export function PaymentTermsConditions({sheetState, setSheetState, setLoading, submitOrder}:PaymentTermsConditionsI) {
    const sheetRef = useRef<BottomSheetRef>(null);
    const [expanded, setExpanded] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const vendor = useAppSelector(selectVendor);

    const collapseToggle = () => {
        setExpanded(!expanded);
    }

    const submit = () => {
        if(submitted){
            return;
        }
        setSubmitted(true);
        sheetState.gateway.submit();
        setTimeout(() => {
            close();
        },3000)
    }

    const close = () => {
        setSheetState({open: false});
        setSubmitted(false);
        setTimeout(() => {
            setExpanded(false);
            setLoading(false);
        },100)
    }

    return (
        <BottomSheet
            open={sheetState.open}
            ref={sheetRef}
            onDismiss={close}
            defaultSnap={({maxHeight}) => {
                return maxHeight - (maxHeight * (expanded ? 0.15 : 0.65))
            }}
            snapPoints={({maxHeight}) => [
                maxHeight -(maxHeight * (expanded ? 0.15 : 0.65)),
            ]}
        >
            <div className="indent mt-8">
                {expanded &&
                    <ActionButton onClick={collapseToggle} style={{position: "absolute", top: 10, right: 10}}>
                        <IoIosClose size="32"/>
                    </ActionButton>
                }

                {expanded &&
                    <>
                        <h1 className="mb-1"> <span className="font-bold">Company Name</span>: {vendor.EstablishmentName ?? ""} </h1>
                        <h1 className="mb-1"> <span className="font-bold">Address</span>: {vendor.EstablismentAddress ?? ""}</h1>
                        <h1 className="mb-1"> <span className="font-bold">Company Registration Number</span>: {vendor.CompanyNumber ?? ""} </h1>
                        <h1 className="mb-1"> <span className="font-bold">Telephone</span>: {vendor.EstablismentPhoneNumber ?? ""} </h1>
                        <div className="mb-8"></div>
                    </>
                }
                <h1 onClick={collapseToggle}
                    className="text-center row-start-1 col-start-1 col-span-full text-2xl fore-color">Payment Conditions
                </h1>

                {!expanded &&
                    <p onClick={collapseToggle}
                       className="text-center mt-2 underline">
                        {expanded ? 'Hide': 'Read'} Payment Conditions
                    </p>
                }


                <Collapsible className={expanded ? "expanded no-shadow" : ""}>
                    <h2 className="row-start-1 col-start-1 col-span-full text-xl fore-color mt-4 font-bold">
                        By card (credit or debit)
                    </h2>
                    <p className="mt-2">
                        When placing your order through nina website, you can pay by credit or debit card (Visa or Mastercard that
                        support electronic payments).
                    </p>
                    <p className="mt-2">Selecting Card Payment will take you to the Merchant’s Secure Payment Portal to enter your debit / credit card
                        details.
                    </p>
                    <p className="mt-2">
                        After entering your card details and confirming the payment, your bank will approve the transaction and your
                        order will be completed. The amount of your order will be blocked for a period of time (usually is one calendar
                        day) and then will be debited on your card and will not be available for any other purpose. You will then return to
                        nina webpage and receive a notification about the completion of your order.
                    </p>
                    <p className="mt-2">
                        All transactions with electronic payment are made in euros (€).
                    </p>
                    <p className="mt-2">
                        You can contact your issuing bank to find out if your card supports electronic payments.
                    </p>
                    <h2 className="row-start-1 col-start-1 col-span-full text-xl fore-color mt-8 underline">
                        Additional Information - Explanations for card transactions
                    </h2>
                    <p className="mt-4 font-bold">a) How secure is the debit / credit card payment process?</p>
                    <p className="mt-2">The process is completely safe. Payment is not made in nina environment, but the user is transferred to the Merchant’s secure payment server.</p>
                    <p className="mt-4 font-bold">b) What is the required CVC / CVV field?</p>
                    <p className="mt-2">The CVC / CVV field is on the back of your debit / credit card and is a three-digit number. You are asked for additional confirmation and security in order to increase your protection.</p>
                    <p className="mt-2">Depending on the transaction security levels of your issuing bank, you may be asked for additional identification (code via SMS, e-com pin, etc.).</p>
                    <p className="mt-4 font-bold">c) What will happen if a mistake is made during the payment (e.g. the connection is cut off or I press Cancel during the transfer)?</p>
                    <p className="mt-2">Whatever happens, the transaction has been recorded by us and your money will not be lost (unless of course we get approval for your card and the amount is indeed committed). The process is automated, controlled and there is no way your money can be lost.</p>
                    <h2 className="row-start-1 col-start-1 col-span-full text-xl fore-color mt-8 font-bold">
                        Refund policy
                    </h2>
                    <p className="mt-2">After completing your order, you will receive the payment receipt, with the details of your transaction. Proof of payment is proof that you paid for your online order. For the order of the products you will receive a retail receipt from the store. Currently, we do not support invoices.</p>
                    <p className="mt-2">For orders that have been canceled within one calendar day from their completion, the full amount paid electronically will be released automatically. For orders that have been canceled after the end of the first calendar day from their completion, the full amount paid will be refunded in accordance with Adyen operating rules. For modified orders, reducing the total amount of the order, there will be a partial refund equal to the difference between the amount paid and the new final order amount.</p>
                    <p className="mt-2">The Business will process and complete refunds based on the process, however it is possible that the bank or any other payment processor may require additional time. Thus, the changes in your account resulting from the refund process will be visible after a few days.</p>
                    <p className="mt-2">Nina is responsible for the immediate transmission of the order to the restaurant which is responsible for its acceptance and preparation. The non-service of the order consists in the non-delivery of the order by the restaurant after finding a lack of product or the inability of the restaurant to serve the order and therefore the inability to complete the order and in no case means the dissatisfaction of the customer with the quality of the product, unless the quality is subject to health violations.</p>
                    <h2 className="row-start-1 col-start-1 col-span-full text-xl fore-color mt-8 font-bold">
                        Complaints
                    </h2>
                    <p className="mt-2">Users&#39; complaints regarding any order with the cooperating stores should be addressed directly to these stores. The respective cooperating store bears the sole responsibility towards the Users for the proper fulfillment of the (sale) agreement between them, in accordance with the provisions of the above regarding the limitation of the Company's liability. The contact details of the cooperating stores are posted on menu information Tab.</p>
                    <p className="mt-2">
                        If Users have complaints about the e-ordering services provided by the Company, they can contact the
                        Customer Service Department via email at <span className="text-blue-700 font-medium">info@ninacy.com</span> or by phone: <span
                        className="text-blue-700 font-medium">25319100</span>. Any personal data of
                        Users that comes to our knowledge for the submission of complaints will be treated in accordance with our
                        current privacy policy.
                    </p>
                    <h2 className="row-start-1 col-start-1 col-span-full text-xl fore-color mt-8 font-bold">
                        Periodic Changes
                    </h2>
                    <p className="mt-2">
                        The company is constantly expanding, updating and improving its website and its application, and related
                        products and services, and updating this policy accordingly. We recommend that you read the Payment Methods
                        and Return Policy on a regular basis to be informed of any changes to the content. This text is subject to change without notice to the users of the website and the application of the company.
                    </p>
                </Collapsible>

                <div className="pt-8"></div>
                {sheetState.gateway &&
                    <div className="flex justify-center items-center mt-8">
                        <AppButton onClick={submit}>
                            {submitted ?'Loading...' : 'Accept & Continue'}
                        </AppButton>
                    </div>
                }

            </div>

        </BottomSheet>
    )
}
