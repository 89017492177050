import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {selectVendor} from "../vendorSlice";
import {TextP, Title1} from "../../../styled_components/General";
import PinInput from "react-pin-input";
import React, {useState} from "react";
import {Navigate} from "react-router-dom";
import {useSessionGuard} from "../../session/hooks";
import {setPinStatus} from "../../session/sessionSlice";

export function PinScreen() {
    useSessionGuard();
    const dispatch = useAppDispatch();
    const vendor = useAppSelector(selectVendor);
    const [validated, setValidated] = useState(false);
    const [errored, setErrored] = useState(false);
    const [el, setEl] = useState<PinInput>();

    const handlePin = (value: string, index: number) => {
        if(value === vendor.SecretKey){
            dispatch(setPinStatus({prq: true, pst: true}))
            setValidated(true);
        }else{
            setErrored(true);
            el?.clear();
        }
    }

    const inputStyle = {
        borderColor: '#ccc',
        borderRadius: "15px",
        height: "55px",
        width: "55px",
        margin: "0"
    }


    return (
        <div className="pin-screen flex items-center justify-center h-screen">
            <div className="text-center">
                <p className="mb-2 text-lg">Enter the Pin for</p>
                <Title1 color="var(--theme-primary-color)">{vendor.Name}</Title1>
                <p className="mt-4 mb-10 text-lg">Contact the venue waiters for <br/> the pin below</p>
                <TextP className="text-left px-4">Pin Number</TextP>
                <PinInput
                    ref={(n) => {
                        if(n){
                            setEl(n)
                        }
                    }}
                    focus={true}
                    length={4}
                    type="numeric"
                    inputMode="number"
                    style={{padding: '10px'}}
                    inputStyle={inputStyle}
                    inputFocusStyle={{borderColor: "var(--theme-primary-color)"}}
                    onComplete={handlePin}
                    autoSelect={true}
                    regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                />
                {errored &&
                    <TextP color={'red'}>PIN is not valid</TextP>
                }
            </div>
            {validated && <Navigate replace to="/menu"/>}

        </div>
    )
}
