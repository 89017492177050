import {ActionButton} from "../../../styled_components/ActionButton";
import {IoIosClose} from "react-icons/io";
import React, {useEffect, useState} from "react";
import {TextP, Title1, Title2} from "../../../styled_components/General";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {selectTable, selectVendor, selectVendorSchedule, setVendorSchedule} from "../../vendor/vendorSlice";
import {Translate} from "../../intl/translator";
import {TableInfo} from "../../../styled_components/Menu";
import {selectCart} from "../cartSlice";
import {Cart} from "../Cart";
import {CartScreenItem} from "./CartScreenItem";
import {CartItem} from "../CartItem";
import {ProductScreen} from "../../menu/components/ProductScreen/ProductScreen";
import {CartStickyFooter} from "../../../styled_components/Cart";
import {AppButton} from "../../../styled_components/Core";
import {useSessionGuard} from "../../session/hooks";
import {canUserOrder} from "../../menu/menuService";
import {getVendor, getVendorSchedule} from "../../vendor/vendorRepository";
import {useNavigate} from "react-router-dom";
import {VisibilityStatusGuard} from "../../vendor/components/VisibilityStatusGuard";
import {AiOutlineLoading3Quarters} from "react-icons/ai";
import {CartComments} from "./CartComments";
import {selectUser} from "../../user/userSlice";
import {createRequest, navigateToTips } from "../cartRepository";
import { Order } from "../Order";
import {selectSession} from "../../session/sessionSlice";
import CONFIG from "../../../app/config";
import {UserStatus} from "../../user/userInterface";
export function CartScreen() {
    useSessionGuard();
    const strings = {
        s_cart: Translate('cart'),
        s_table: Translate('table'),
        s_order_items: Translate('order_items'),
        s_empty_cart: Translate('cart_is_empty'),
        s_cart_screen_description: Translate('cart_screen_description'),
        s_checkout: Translate('checkout'),
        s_service_hours: Translate('service_hours')
    };
    const table = useAppSelector(selectTable);
    const cartSliceState = useAppSelector(selectCart);
    const vendor = useAppSelector(selectVendor);
    const user = useAppSelector(selectUser);
    const scheduleHours = useAppSelector(selectVendorSchedule);
    const navigate = useNavigate();
    const [cart, setCart] = useState<Cart>(new Cart({items: []}));
    const [loading, setLoading] = useState(true);
    const dispatch = useAppDispatch();
    const [shopOpen, setShopOpen] = useState(true)
    const cartI = useAppSelector(selectCart);
    const session = useAppSelector(selectSession);

    useEffect(() => {
        Promise.all([
            getVendor(vendor.OutletID),
            getVendorSchedule(vendor.OutletID)
        ]).then(res => {
            dispatch(setVendorSchedule(res[1]));
            // console.log("cart vendor status:"+vendor.OpenStatus?.Status);

                      if (!vendor.OpenStatus?.Status){
                navigate("/menu")
            }
            setShopOpen(vendor.OpenStatus?.Status ?? false);
            setLoading(false);
        })
    }, [dispatch, vendor.OutletID])

    useEffect(() => {
        setCart(new Cart(cartSliceState));
    },[cartSliceState])


    const checkout = () => {
        if (
            !canUserOrder({user, vendor})
        ){
            localStorage.setItem("intent", "/checkout");
            navigate("/login")
        }else{
            if (vendor.EnableTips != "1"){
                navigate("/checkout")
            }else{
            // 
                let order: Order = new Order(cartI);
                order.info.Tableid = parseInt(table?.TableID ?? "1");
                order.info.Scantimestamp = session.sst.toString();
                order.info.Outletid = parseInt(vendor.OutletID);
                order.info.Reguserid = user.Verified !== UserStatus.Verified ? CONFIG.GUEST_USER_ID : user.RegisteredUserID;
                createRequest(order, '2',(cart.calcCartPrice() / 100).toFixed(2).toString()).then((res) => {
                    // const pastOrder: PastOrderI = {
                    //     VendorId: vendor.OutletID,
                    //     OrderId: res.OrderID
                    // }
                    // console.log("RequestID: "+res.OrderID);
                    navigateToTips(res.OrderID);
            
                    // document.location.replace("/order");
                }).catch(e => navigate('/order/failed'))
            }
        }
    }

    return (
        <div id="cart-screen" className="min-h-screen">
            <div className="grid py-5 indent items-center content-center grid-cols-2 grid-rows-1 mb-4">
                <div className="col-start-1 col-span-1 row-start-1">
                    <ActionButton onClick={() => {navigate("/menu")}}>
                        <IoIosClose size="32"/>
                    </ActionButton>
                </div>
                <h1 className="text-center row-start-1 col-start-1 col-span-full text-3xl fore-color">
                    {strings.s_cart}
                </h1>
            </div>
            <div className="flex indent place-content-between py-5">
                <div>
                    <h2 className="text-3xl fore-color">{strings.s_order_items}</h2>
                    <TextP>{strings.s_cart_screen_description}</TextP>
                </div>
                <div>
                    {table &&
                        <TableInfo align={"end"} padding={"15px 0 10px"} size={16}>
                            {strings.s_table} #<span>{table.TableName}</span>
                        </TableInfo>
                    }
                </div>
            </div>
            <hr className="py-3"/>

            <div className="indent pb-8">
                {cart.Items.map(item => {
                    const cartItem = new CartItem(item);
                    return <CartScreenItem key={item.Id} item={cartItem} />
                })}
                {cart.Items.length === 0 &&
                    <p className="text-2xl text-gray-300 text-center">{strings.s_empty_cart}</p>
                }
            </div>

            <div className="indent pb-8">
                <hr className="py-3"/>
                <div className="flex place-content-between items-center content-center">
                    <div>
                        <Title2 className="inline-block pr-2" color={"var(--theme-modded-fore-color)"}>Total</Title2>
                        <TextP className="inline-block">
                            ({cart.itemsCount()} item{cart.itemsCount() > 1 && "s"})
                        </TextP>
                    </div>
                    <Title1>{cart.displayFormattedPrice()}</Title1>
                </div>
            </div>



            {vendor.GNotes == "1" &&
            <div className="indent pb-32">
                <CartComments />
            </div>
            }
            {/* @todo extract to separate component */}
            <VisibilityStatusGuard showInVisibilityStatuses={[]}>
                <CartStickyFooter enabled={cart.Items.length > 0}>
                    {loading &&
                        <div className="flex justify-center items-center pt-8 pb-4">
                            <AiOutlineLoading3Quarters size={40}
                                                       className="animate-spin m-auto"
                                                       color="var(--theme-primary-color)"/>
                        </div>
                    }
                    {!loading &&
                        <AppButton
                            disable={!shopOpen}
                            onClick={() => {
                                if(shopOpen){
                                    checkout();
                                }
                            }}>
                            {shopOpen ? strings.s_checkout : strings.s_service_hours + ' ' + scheduleHours.working_hours}
                        </AppButton>
                    }

                </CartStickyFooter>
            </VisibilityStatusGuard>
            <ProductScreen />
        </div>
    )
}
