import {useEffect, useState} from "react";
import {jccRequest} from "../../../cartRepository";
import {useParams} from "react-router-dom";

export function JccGatewayRedirect() {
    const [loading, setLoading] = useState(true);
    const [form, setForm] = useState("");
    let { orderId } = useParams();
    let { accountId } = useParams();
    let { orderTotal } = useParams();

    useEffect(() => {
        if(orderId && orderTotal && accountId){
            jccRequest(orderId, orderTotal, accountId)
                .then(res => {
                    setForm(res);
                    setLoading(false);
                })
                .catch(e => console.log(e))
        }
    },[orderId,accountId,orderTotal])

    useEffect(() => {
        if(!loading){
            setTimeout(() => {
                // @ts-ignore
                document.forms["paymentForm"].submit()
            }, 200)
        }
    }, [loading])

    // @ts-ignore
    return(
        <>
            {!loading &&
                <div dangerouslySetInnerHTML={{__html: form}}></div>
                // <iframe title="jcc-payment" srcDoc={form} ></iframe>
            }
        </>

    );

}
