import {MenuItemQuantity} from "../../../../styled_components/Menu";
import {TextP} from "../../../../styled_components/General";
import {NinaOrderItemI, NinaOrderItemVariationI} from "../../cartInterfaces";
import React from "react";
import {useSessionGuard} from "../../../session/hooks";
import {formatCurrency} from "../../../core/utils/format";

interface OrderStatusScreenItemI {
    item: NinaOrderItemI
}
export function OrderStatusScreenItem({item}: OrderStatusScreenItemI) {
    useSessionGuard();
    const calcItemPrice = (): string => {
        let price: number = parseFloat(item.Price) * parseInt(item.Quantity);
        item.nameWithVariation.forEach(variation => {
            price += parseFloat(variation.price ?? 0);
        })
        return formatCurrency(price);
    }
    return (
        <div className="mb-4">
            <div className="flex place-content-between items-center content-center">
                <div className="flex items-center">

                    <span className="inline-block text-lg pr-2 fore-color">
                        {item.ItemName}
                    </span>
                    <MenuItemQuantity>{item.Quantity}</MenuItemQuantity>
                </div>
                <div>
                    <span className="text-lg ml-2" style={{
                        color: "var(--theme-primary-color)"
                    }}>{calcItemPrice()}</span>
                </div>
            </div>
            <ul className="list-disc ml-8">
                {
                    item.nameWithVariation.map(variation => {
                        return <OrderStatusScreenItemVariation key={variation.OptionID} {...variation} />
                    })
                }
            </ul>
            {item.Notes &&
                <div className="indent mt-1 text-lg">
                    <p className="" style={{color: "var(--theme-primary-color)"}}>Notes:</p>
                    <TextP>{item.Notes}</TextP>
                </div>
            }
            <hr className="my-3"/>
        </div>
    )
}

function OrderStatusScreenItemVariation(variation: NinaOrderItemVariationI) {
    return (
        <li style={{color: "grey"}} key={variation.OptionID} className="">
            <span className="text-lg">
                {variation.SelectionName}
                {variation.Quantity > 1 &&
                    <span> (x{variation.Quantity})</span>
                }
            </span>
        </li>
    )
}
