import {NinaOrderI, OrderItemI} from "./cartInterfaces";

export class NinaOrder{
    info: NinaOrderI;
    orderItems: Array<OrderItemI> = [];

    constructor(json: NinaOrderI) {
        this.info = json;
    }


}
