import axios from "axios";
import {CompanyUserI, UserInterface, WaiterI} from "./userInterface";
import CONFIG from "../../app/config";
import {MenuItem} from "../menu/MenuItem";
import {VendorInterface} from "../vendor/vendorInterface";
import {CartInterface, NinaOrderI} from "../cart/cartInterfaces";
import {PastOrderI} from "../cart/ordersSlice";
import {CartItem} from "../cart/CartItem";
import {MenuItemInterface} from "../menu/menuInterface";

export function userLogin(phone: string, phoneCode: string) {
    return new Promise<UserInterface>((resolve, reject) => {
        axios.get(`${CONFIG.API_URL}/user/login?phone=${phone}&phoneCode=${phoneCode}`)
            .then(res => {
                if(res.status === 200) {
                    const user :UserInterface = res.data;
                    resolve(user);
                }else{
                    reject(res.status)
                }
            })
            .catch(e => reject(e))
    })
}

export function waiterLogin(username: string, password: string) {
    return new Promise<WaiterI>((resolve, reject) => {
        axios.get(`${CONFIG.API_URL}/user/login/waiter?username=${username}&password=${password}`)
            .then(res => {
                if(res.status === 200) {
                    const waiter :WaiterI = res.data;
                    resolve(waiter);
                }else{
                    reject(res.status)
                }
            })
            .catch(e => reject(e))
    })
}

export function userSendOtp(phone: string, phoneCode: string) {
    let pCode = encodeURIComponent(phoneCode);
    return new Promise<string>((resolve, reject) => {
        axios.get(`${CONFIG.API_URL}/user/otp/send?phone=${phone}&phoneCode=${pCode}`)
            .then(res => {
                if(res.status === 200) {
                    const otp: string = res.data;
                    resolve(otp);
                }else{
                    reject(res.status)
                }
            })
            .catch(e => reject(e))
    })
}

export function userVerifyOtp(phone: string, otp: string) {
    return new Promise<UserInterface>((resolve, reject) => {
        axios.get(`${CONFIG.API_URL}/user/otp/verify?phone=${phone}&otp=${otp}`)
            .then(res => {
                if(res.status === 200) {
                    const user :UserInterface = res.data;
                    resolve(user);
                }else{
                    reject(res.status)
                }
            })
            .catch(e => reject(e))
    })
}

export function userUpdateData(user: UserInterface) {
    return new Promise((resolve, reject) => {
        axios.post(`${CONFIG.API_URL}/user/update`,{...user})
            .then(res => {
                if(res.status === 200) {
                    const user :UserInterface = res.data;
                    resolve(user);
                }else{
                    reject(res.status)
                }
            })
            .catch(e => reject(e))
    })
}

export function userFavouriteProducts(userId: string, outletId: string) {
    return new Promise<Array<MenuItem>>((resolve, reject) => {
        if(userId === "0"){
            resolve([]);
        }
        axios.get(`${CONFIG.API_URL}/user/favourite/products?userId=${userId}&outletId=${outletId}`)
            .then(res => {
                if(res.status === 200) {
                    const result :Array<MenuItem> = res.data;
                    resolve(result);
                }else{
                    reject(res.status)
                }
            })
            .catch(e => reject(e))
    })
}

export function userFavouriteProductToggle(userId: string, itemId: string) {
    return new Promise<boolean>((resolve, reject) => {
        axios.post(`${CONFIG.API_URL}/user/favourite/product/toggle`, {userId, itemId})
            .then(res => {
                resolve(true)
            }).catch(e => reject(e))
    })
}

export function userFavouriteOutlets(userId: string) {
    return new Promise<Array<VendorInterface>>((resolve, reject) => {
        if(userId === "0"){
            resolve([]);
        }
        axios.get(`${CONFIG.API_URL}/user/favourite/outlets?userId=${userId}`)
            .then(res => {
                if(res.status === 200) {
                    const result :Array<VendorInterface> = res.data;
                    resolve(result);
                }else{
                    reject(res.status)
                }
            })
            .catch(e => reject(e))
    })
}

export function userFavouriteOutletToggle(userId: string, outletId: string) {
    return new Promise<boolean>((resolve, reject) => {
        axios.post(`${CONFIG.API_URL}/user/favourite/outlet/toggle`, {userId, outletId})
            .then(res => {
                resolve(true)
            }).catch(e => reject(e))
    })
}

export function userCompletedOrders(userId: string, outletId: string) {
    return new Promise<Array<NinaOrderI>>((resolve,reject) => {
        axios.get(`${CONFIG.API_URL}/user/orders?userId=${userId}&outletId=${outletId}`)
            .then(res => {
                const orders: Array<NinaOrderI> = res.data;
                resolve(orders);
            }).catch(e => reject(e))
    })
}

export function getCompanyUser(userId: string, outletId: string, companyId: string){
    return new Promise<CompanyUserI>((resolve, reject) => {
        axios.get(`${CONFIG.API_URL}/user/company?userId=${userId}&outletId=${outletId}&companyId=${companyId}`)
            .then(res => {
                const companyUser: CompanyUserI = res.data;
                resolve(companyUser);
            }).catch(e => reject(e))
    })
}


export function prepareUserLastCart(
    order: NinaOrderI,
    menuItemsList: Array<MenuItemInterface>
) {
    let cart: CartInterface = {items: []};
    order.Orderitems.forEach(orderItem => {
        let productIndex = menuItemsList.findIndex((menuItem) => menuItem.MenuItemID === orderItem.MenuItemID);
        if(productIndex >= 0){
            let product = menuItemsList[productIndex]
            let cartItem = new CartItem({
                Product: product,
                Qty: parseInt(orderItem.Quantity),
                Notes: orderItem.Notes,
            })
            orderItem.nameWithVariation.forEach(orderItemVariation => {
                //find the product variation group of the order item variation
                let productVariationGroup = product.Variations.find(productVariation => {
                    return productVariation.ItemVariationID === orderItemVariation.VariationID
                })
                if(productVariationGroup){
                    //find the productVariationGroup option
                    let productVariationOption = productVariationGroup.OptionList.find(productVariationOption => {
                        return productVariationOption.OptionID === orderItemVariation.OptionID
                    })
                    if(!productVariationOption) return;
                    // check if is single/radio or multiple
                    if(productVariationGroup.Multiple !== "0"){
                        for (let i = 0; i < orderItemVariation.Quantity; i++){
                            cartItem.addVariationQtyToGroup({
                                group: productVariationGroup, option: productVariationOption})
                        }
                    }else{
                        cartItem.toggleVariationSingleToGroup({
                            group: productVariationGroup, option: productVariationOption})
                    }
                }
            })
            cart.items.push(cartItem)
        }
    })
    let pastOrder: PastOrderI = {
        VendorId: order.OutletID,
        OrderId: order.OrderID,
        Cart: cart
    }
    return pastOrder;
}
