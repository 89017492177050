import {Order} from "../../Order";
import {Translate} from "../../../intl/translator";
import React, {useEffect, useRef, useState} from "react";
import {BottomSheet, BottomSheetRef} from "react-spring-bottom-sheet";
import {getCompanyUser} from "../../../user/userRepository";
import {ListItemCard} from "../../../../styled_components/General";
import {AiOutlineLoading3Quarters} from "react-icons/ai";
import {useAppSelector} from "../../../../app/hooks";
import {selectUser} from "../../../user/userSlice";
import {selectVendor} from "../../../vendor/vendorSlice";
import {getVendorCompany} from "../../../vendor/vendorRepository";

export interface AccountChargeBottomSheetState{
    open: boolean,
    order?: Order
}

interface AccountChargeBottomSheetI{
    sheetState: AccountChargeBottomSheetState,
    setSheetState: any,
    setLoading: any,
    submitOrder: any
}

interface CompanyUserHouseAccountItemI {
    name: string,
    code: string
}

export function AccountChargeBottomSheet({sheetState, setSheetState, setLoading, submitOrder}:AccountChargeBottomSheetI) {
    const strings = {
        s_title: Translate('account_payment_sheet_title'),
        s_error: Translate('account_payment_sheet_error'),
        s_description: Translate('account_payment_sheet_description'),
    }

    const sheetRef = useRef<BottomSheetRef>(null);
    const [dataLoading, setDataLoading] = useState(true);
    const [items, setItems] = useState<Array<CompanyUserHouseAccountItemI>>([]);
    const user = useAppSelector(selectUser);
    const outlet = useAppSelector(selectVendor);

    const submit = (houseAccountId: string) => {
        if(sheetState.order){
            let order: Order = sheetState.order;
            order.info.Houseaccount = houseAccountId;
            setSheetState({open: false});
            setDataLoading(true);
            submitOrder(order)
        }
    }

    useEffect(() => {
        if(sheetState.open){
            getVendorCompany(outlet.OutletID, user.RegisteredUserID).then(company => {
                getCompanyUser(user.RegisteredUserID, outlet.OutletID, company.CompanyID)
                    .then(companyUser => {
                        setDataLoading(false);
                        let itemsArray: Array<CompanyUserHouseAccountItemI> = [];
                        if(companyUser && companyUser.PhoneNum){
                            itemsArray.push({
                                name: companyUser.PhoneNum,
                                code: companyUser.PhoneNum
                            })
                        }
                        if(companyUser && companyUser.Department.length > 0 && companyUser.Admin === "1"){
                            companyUser.Department.forEach(dep => {
                                itemsArray.push({
                                    name: dep.Name,
                                    code: dep.Code
                                })
                            })
                        }
                        setItems(itemsArray);
                    })
                    .catch(e => console.log(e))
            })
        }
    }, [sheetState.open, user, outlet])

    return (
        <BottomSheet
            open={sheetState.open}
            ref={sheetRef}
            onDismiss={() => {
                setDataLoading(true);
                setSheetState({open: false});
                setTimeout(() => {
                    setLoading(false);
                },100)
            }}
            defaultSnap={({maxHeight}) => {
                return maxHeight - maxHeight / 3
            }}
            snapPoints={({maxHeight}) => [
                maxHeight - maxHeight / 3,
            ]}
        >
            <h1 className="text-center row-start-1 col-start-1 col-span-full text-2xl fore-color">
                {strings.s_title}
            </h1>
            <div className="indent pt-8">
                {dataLoading &&
                    <div className="flex justify-center items-center pt-16">
                        <AiOutlineLoading3Quarters size={50}
                                                   className="animate-spin m-auto"
                                                   color="var(--theme-primary-color)"/>
                    </div>
                }
                {!dataLoading &&
                    <div>
                        <p className="fore-color">{strings.s_description}</p>
                        {items.map(item => {
                        return <ListItemCard
                            key={item.code}
                            onClick={() => submit(item.code)}
                            className="my-4">
                            <p className="text-lg">{item.name}</p>
                        </ListItemCard>
                        })}
                        {items.length === 0 &&
                            <p className="text-lg italic text-center mt-4 text-red-600 fore-color">
                                {strings.s_error}
                            </p>
                        }
                    </div>
                }

            </div>
        </BottomSheet>
    )
}
