import { AppCreditsContainer } from "../../../styled_components/Core";
import { useAppSelector } from "../../../app/hooks";
import { selectTable, selectVendor, selectVendorSchedule } from "../../vendor/vendorSlice";
export function AppCredits() {
    const vendor = useAppSelector(selectVendor);
    return (

        <AppCreditsContainer className="indent">
            <hr />



            {
                vendor.OrderSystemFlag == 1 ? (
                    <p className={'pb-4 text-gray-500'}>Nina Technologies Ltd.</p>) :
                    (<p className={'pb-4 text-gray-500'}>Seamless Integration</p>)
            }




{vendor.OrderSystemFlag == 4 ? (
                <div className="flex justify-around w-full pb-2">
                    <a href="https://www.restuspos.com" target="_blank" rel="noopener noreferrer" className="text-xl fore-color">
                        <img src="https://apps.ninacy.com/RestusWinmaxLogo.png" alt="restus logo"/>
                    </a> 
                    <a href="https://www.ninatech.co/" target="_blank" rel="noopener noreferrer" className="text-xl fore-color">
                        <img src="/images/logos/footer-logo.svg" alt="nina logo"/>
                    </a>
                </div>
                ) : vendor.OrderSystemFlag == 3 ? (
                    <div className="flex justify-around w-full pb-2">
                        <a href="https://www.ninatech.co/" target="_blank" rel="noopener noreferrer" className="text-xl fore-color">
                            <img src="https://apps.ninacy.com/aloha.png" alt="aloha logo"/>
                        </a> 
                        <a href="https://www.ninatech.co/" target="_blank" rel="noopener noreferrer" className="text-xl fore-color">
                            <img src="/images/logos/footer-logo.svg" alt="nina logo"/>
                        </a>
                    </div>
                ) : vendor.OrderSystemFlag == 7 ? (
                    <div className="flex justify-around w-full pb-2">
                        <a href="https://untill.com" target="_blank" rel="noopener noreferrer" className="text-xl fore-color">
                            <img src="https://apps.ninacy.com/until.jpg" alt="until logo"/>
                        </a> 
                        <a href="https://www.ninatech.co/" target="_blank" rel="noopener noreferrer" className="text-xl fore-color">
                            <img src="https://apps.ninacy.com/nina.png" alt="nina logo"/>
                        </a>
                    </div>
                ) : vendor.OrderSystemFlag == 8 || vendor.OrderSystemFlag == 9 ? (
                    <div className="flex justify-around w-full pb-2">
                        <a href="https://www.ninatech.co/" target="_blank" rel="noopener noreferrer" className="text-xl fore-color">
                            <img src="https://apps.ninacy.com/micros.png" alt="micros logo"/>
                        </a> 
                        <a href="https://www.ninatech.co/" target="_blank" rel="noopener noreferrer" className="text-xl fore-color">
                            <img src="https://apps.ninacy.com/nina.png" alt="nina logo"/>
                        </a>
                    </div>
                ) : (
                    <div className="flex justify-around w-full pb-2">
                        <a href="https://www.ninatech.co/" target="_blank" rel="noopener noreferrer" className="text-xl fore-color">
                            <img src="https://apps.ninacy.com/nina.png" alt="nina logo"/>
                        </a>
                    </div>
                )
}

        </AppCreditsContainer>

    )
}
