import {MenuCategoryInterface, MenuSubcategoryInterface} from "../../menuInterface";
import {CategoriesScreenSubcategoryItem} from "../../../../styled_components/Menu";
import {v4 as uuid} from "uuid";
import * as Scroll from "react-scroll";
import {useAppDispatch} from "../../../../app/hooks";
import {setCategoriesPanelStatus} from "../../../core/panelsSlice";

interface CategoriesScreenSubcategoriesInterface {
    category: MenuCategoryInterface
}

export function CategoriesScreenSubcategories({category}: CategoriesScreenSubcategoriesInterface) {
    const scroller = Scroll.scroller;
    const dispatch = useAppDispatch();

    const showSubCategory = (subcategory: MenuSubcategoryInterface) => {
        dispatch(setCategoriesPanelStatus(false));
        scroller.scrollTo(('subcategory-'+subcategory.Id), {
            smooth: false,
            offset: -210,
            ignoreCancelEvents: true,
        });
    }

    return (
        <>
            {category.Subcategories.map(subcategory => {
                if(subcategory.Name !== 'None') {
                    return <CategoriesScreenSubcategoryItem
                        key={uuid()}
                        onClick={() => {showSubCategory(subcategory)}}
                            >
                        {subcategory.Name}
                    </CategoriesScreenSubcategoryItem>
                }
                return null;
            })}
        </>
    );
}
