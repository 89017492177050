import {MenuSubcategoryInterface} from "../../menuInterface";
import {Title2} from "../../../../styled_components/General";
import {MenuItemMemoized} from "./MenuItem";
import {MenuCategoryItems} from "../../../../styled_components/Menu";
import {v4 as uuid} from "uuid";
import {generateSubcategoryId} from "../../menuService";
import React from "react";

export function MenuSubcategory(subcategory: MenuSubcategoryInterface) {

    const identifier = generateSubcategoryId(subcategory.categoryId ?? "", subcategory);
    return (
        <>
            {subcategory.Name !== 'None' &&
                <MenuCategoryItems id={identifier} className="mb-6 subcategory-sticky-identifier" key={uuid()}>
                    <Title2 key={uuid()} className="indent mt-4" color="var(--theme-primary-color)">{subcategory.Name}</Title2>
                    {subcategory.Items.map(item => {
                        if(!item.filtered) {
                            return <MenuItemMemoized key={uuid()} inCart={0} item={item}/>
                        }
                        return null;
                    })}
                </MenuCategoryItems>
            }
            {subcategory.Name === 'None' &&
                <MenuCategoryItems id={identifier}
                                   className="mb-10 subcategory-sticky-identifier"
                                   key={uuid()}
                >
                    {subcategory.Items.map(item => {
                        if(!item.filtered) {
                            return <MenuItemMemoized key={uuid()} inCart={0} item={item}/>
                        }
                        return null;
                    })}
                </MenuCategoryItems>
            }

        </>
    );
}

export const MenuSubcategoryMemo = React.memo(MenuSubcategory);
