import React from 'react';
import {
    MenuItemContainer,
    MenuItemDescription,
    MenuItemDetails,
    MenuItemImage,
    MenuItemPrice,
    MenuItemQuantity,
    MenuItemTag,
    MenuItemTitle
} from "../../../../styled_components/Menu";
import {MdStarRate} from "react-icons/md";
import {MenuItemInterface} from "../../menuInterface";
import {v4 as uuid} from "uuid";
import Config from "../../../../app/config";
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {setItemSelected, setProductView} from "../../productSlice";
import {canShowMenuItemPrice} from "../../menuService";
import {VisibilityStatusGuard} from "../../../vendor/components/VisibilityStatusGuard";
import {VendorVisibility} from "../../../vendor/vendorInterface";
import {selectVendor} from "../../../vendor/vendorSlice";
import {formatCurrency} from "../../../core/utils/format";

interface MenuItemProps {
    inCart: number;
    popular?: boolean;
    item: MenuItemInterface
}

export function MenuItem(props: MenuItemProps) {
    const dispatch = useAppDispatch();
    const vendor = useAppSelector(selectVendor)

    const formatLength = (value: string, limit: number, append?: string) => {
        if (value && value.length > limit) {
            value = value.substr(0, limit);
            if (append) {
                value += append;
            }
        }
        return value;
    }

    const openItem = () => {
        dispatch(setItemSelected(props.item));
        dispatch(setProductView({
            mode: "product",
            open: true
        }))
    }

    return (
        <MenuItemContainer onClick={openItem}
                           className={`${props.inCart > 0 ? "inOrder" : ""}`} key={uuid()}>
            <MenuItemDetails>
                <MenuItemTitle>{props.item.ItemName}
                    {props.inCart > 0 &&
                        <MenuItemQuantity>{props.inCart}</MenuItemQuantity>
                    }

                </MenuItemTitle>
                <MenuItemDescription className="mt-2">
                    {formatLength(props.item.Description, 60, "...")}
                </MenuItemDescription>
                <div className="mt-2">
                    {canShowMenuItemPrice({price: props.item.Price}) &&
                        <VisibilityStatusGuard showInVisibilityStatuses={[VendorVisibility.MenuWithPrices]}>
                            <MenuItemPrice className="mr-3">
                                {formatCurrency(Number(props.item.Price), vendor.CurrencySymbol)}
                            </MenuItemPrice>
                        </VisibilityStatusGuard>

                    }

                    {props.item.IsPopular === "1" &&
                        <MenuItemTag> <MdStarRate className="inline-block"/> Popular</MenuItemTag>
                    }

                </div>
            </MenuItemDetails>
            {props.item.FileName &&
                <MenuItemImage>
                    <img src={Config.MENU_IMAGES_URL + props.item.FileName} alt=""/>
                </MenuItemImage>
            }
        </MenuItemContainer>
    );
}

export const MenuItemMemoized = React.memo(MenuItem)
