import {VendorPaymentMethodI} from "../../../vendor/vendorInterface";
import {BsChevronRight} from "react-icons/bs";
import {CheckoutMethodItem} from "../../../../styled_components/Cart";
import React from "react";
import {getPaymentIconById} from "../../cartService";

interface CheckoutMethodI {
    method: VendorPaymentMethodI,
    action: any
}

export function CheckoutMethod(params: CheckoutMethodI) {
    const {method, action} = params;

    const creditCardMethods = ['2', '4', '8','9','10','28'];
    const creditCardOnlineMethods = ['2', '4','9','10','28'];

    return (
        <CheckoutMethodItem onClick={() => {action(method)}}>
            <div className="payment-logo flex justify-center items-center">
                <img src={getPaymentIconById(method.PaymentTypeID)} alt=""/>
            </div>
            <div className="payment-divider"></div>
            <div className="payment-description flex-col">
                {!creditCardOnlineMethods.includes(method.PaymentTypeID) &&
                   <>
                       <>{method.PaymentTypeName}</>
                       {creditCardMethods.includes(method.PaymentTypeID) &&
                           <small>Credit Card</small>
                       }
                   </>
                }
                {creditCardOnlineMethods.includes(method.PaymentTypeID) &&
                    <>
                        Pay Now Online
                        <small>Credit Card</small>
                    </>

                }
            </div>
            <div className="payment-arrow">
                <BsChevronRight size="22" color="#ccc"/>
            </div>
        </CheckoutMethodItem>
    )
}
