import {getOrderCookie, OrderCookieI} from "../../../cart/cartService";
import styled from "styled-components";
import {BsBag} from "react-icons/bs";
import {useEffect, useState} from "react";
import {loadOrder} from "../../../cart/cartRepository";
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {selectLocale} from "../../../intl/intlSlice";
import {setOrderStatusSidebar} from "../../../core/panelsSlice";

const Bubble =  styled.div`
  position: fixed;
  right: 10px;
  top: 190px;
  z-index: 1;
  background: white;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  
  div{
    width: 46px;
    aspect-ratio: 1;
    background: var(--theme-primary-color);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: radiation 3s infinite linear;
    
  }
`;

export function MenuPendingOrderBubble() {
    const orderCookie = getOrderCookie();
    const [status, setStatus] = useState('');
    const locale = useAppSelector(selectLocale);
    const dispatch = useAppDispatch();



    useEffect(() => {
        if(orderCookie.orderId){
            loadOrder(orderCookie.orderId , locale.LanguageID).then(res => {
                setStatus(res.Status.toLowerCase());
            })
        }
    }, [orderCookie.orderId, locale]);

    const viewOrderStatus = () => {
        dispatch(setOrderStatusSidebar(true))
    }

    const canShowBubble = (orderCookie: OrderCookieI, status: string): boolean => {
        let flag = false;
        // if order is not completed show the buble
        if(orderCookie.orderId && !['', 'completed'].includes(status)){
            flag = true;
        }
        // if order completed show bubble after 15min passed
        if(orderCookie.orderId && status === 'completed'){
            let currentTime = new Date().getTime();
            let setTime = parseInt(orderCookie.timeSet ?? currentTime.toString());
            if ((currentTime - setTime) > 900000){
                flag = true;
            }
        }
        return flag;
    }

    return (
        <>
            {canShowBubble(orderCookie, status) &&
                <Bubble onClick={viewOrderStatus}>
                    <div>
                        <BsBag color={'white'} size={22}/>
                    </div>
                </Bubble>
            }
        </>
    )
}
