import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {selectFavourites,   setFavourites,  } from "../../menuSlice";
import {selectItemSelected, selectProductView, setItemSelected, setProductView,} from "../../productSlice";
import React, {useEffect, useRef, useState} from "react";
import {BottomSheet, BottomSheetRef} from "react-spring-bottom-sheet";
import {ProductScreenPrice} from "../../../../styled_components/Product";
import {ProductStickyHeader} from "./ProductStickyHeader";
import {ProductScreenHero} from "./ProductScreenHero";
import {TextP, Title1} from "../../../../styled_components/General";
import {ProductAllergens} from "./ProductAllergens";
import {ProductVariations} from "./Variations/ProductVariations";
import {ProductComments} from "./ProductComments";
import {ProductStickyFooter} from "./ProductStickyFooter";
import {CartItem} from "../../../cart/CartItem";
import {MenuItem} from "../../MenuItem";
import {selectVendor} from "../../../vendor/vendorSlice";
import CONFIG from "../../../../app/config";
import {Heart} from "akar-icons";
import {ActionButton} from "../../../../styled_components/ActionButton";
import {userFavouriteProductToggle} from "../../../user/userRepository";
import {selectUser} from "../../../user/userSlice";
import {UserStatus} from "../../../user/userInterface";
import {canShowProductKitchenNotes} from "../../menuService";
import {MenuItemInterface} from "../../menuInterface";
import {VisibilityStatusGuard} from "../../../vendor/components/VisibilityStatusGuard";
import {VendorVisibility} from "../../../vendor/vendorInterface";
import {formatCurrency} from "../../../core/utils/format";

export function ProductScreen() {
    const dispatch = useAppDispatch();
    const itemSelected = useAppSelector(selectItemSelected);
    const productView = useAppSelector(selectProductView);
    const vendor = useAppSelector(selectVendor);
    const favourites = useAppSelector(selectFavourites);
    const user = useAppSelector(selectUser);
    const [loaded, setLoaded] = useState(false);
    const sheetRef = useRef<BottomSheetRef>(null);
    const [cartItem, setCartItem] = useState<CartItem>();


    useEffect(() => {
        if (itemSelected) {
            initCartItem(itemSelected);
            setLoaded(true);
        }else{
            setLoaded(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productView])

    const initCartItem = (product: MenuItem) => {
        // if is update cart item
        if (productView.mode === "cart" && productView.cartItem) {
            setCartItem(new CartItem(productView.cartItem));
        } else {
            setCartItem(new CartItem({Product: product}));
        }

    }

    const close = () => {
        dispatch(setItemSelected(null));
        dispatch(setProductView({
            open: false,
            mode: "product",
            cartItem: undefined
        }))
    }

    const addAnother = (item: MenuItem) => {
        dispatch(setItemSelected(null));
        dispatch(setProductView({
            open: false,
            mode: "product",
            cartItem: undefined
        }))
        setLoaded(false);
        dispatch(setItemSelected(item));
        dispatch(setProductView({
            open: true,
            mode: "product",
            cartItem: undefined
        }))
    }

    const toggleFavourite = async () => {
        if (itemSelected) {
            if (isFavourite()) {
                let favs = favourites.filter((item: MenuItemInterface) => {
                    return item.MenuItemID !== itemSelected.MenuItemID
                })
                dispatch(setFavourites(favs));
            } else {
                dispatch(setFavourites([...favourites, itemSelected]))
            }
            await userFavouriteProductToggle(user.RegisteredUserID, itemSelected.MenuItemID)
        }
    }

    const isFavourite = (): boolean => {
        let i = favourites.findIndex((item: MenuItemInterface) => {
            return item.MenuItemID === itemSelected?.MenuItemID
        })
        return i >= 0;
    }

    return (
        <BottomSheet
            style={{
                zIndex: '4',
            }}
            open={loaded}
            onDismiss={close}
            defaultSnap={({maxHeight}) => {
                return maxHeight - maxHeight / 9
            }}
            snapPoints={({maxHeight}) => [
                maxHeight - maxHeight / 9,
            ]}
            header={false}
            ref={sheetRef}
        >
            {loaded && productView.open &&
                <div id="product-screen-container">
                    <ProductStickyHeader title={itemSelected ? itemSelected.ItemName : ""} close={close}/>
                    <ProductScreenHero close={close}
                                       gallery={itemSelected?.Gallery || []}
                                       cover={itemSelected?.FileName}
                    />
                    <div className="indent mt-4" color="var(--theme-modded-fore-color)">
                        <div className="flex place-content-between">
                            <Title1 className="flex-1" color="var(--theme-modded-fore-color)">
                                {itemSelected?.ItemName}
                            </Title1>
                            {/* {CONFIG.ENABLE_FAVOURITES && user.Verified === UserStatus.Verified &&
                                <ActionButton onClick={toggleFavourite}>
                                    <Heart color={isFavourite() ? "red" : "grey"} fill={isFavourite() ? "red" : "white"}/>
                                </ActionButton>
                            } */}
                        </div>

                        {itemSelected?.Price !== '-' &&
                            <VisibilityStatusGuard showInVisibilityStatuses={[VendorVisibility.MenuWithPrices]}>
                                <ProductScreenPrice className="mt-3">
                                    {formatCurrency(Number(itemSelected?.Price))}
                                </ProductScreenPrice>
                            </VisibilityStatusGuard>
                        }
                        <TextP className='mt-3'>{itemSelected?.Description}</TextP>
                        <ProductAllergens items={itemSelected?.Allergens || []}/>
                    </div>

                    {cartItem && productView.open &&
                        <>
                            <VisibilityStatusGuard showInVisibilityStatuses={[]}>
                                <>
                                    <div className="pt-6 indent">
                                        <ProductVariations cartItem={cartItem} setCartItem={setCartItem}/>
                                    </div>
                                    {(cartItem.Product.IsBeverage === "1" && vendor.BevNotes === "1" || cartItem.Product.IsFood === "1" && vendor.FNotes === "1") && canShowProductKitchenNotes({vendor})  && 
                                         <ProductComments cartItem={cartItem} setCartItem={setCartItem}/>
                                    }
                                   
                                </>
                            </VisibilityStatusGuard>

                            {productView.mode === "cart" && itemSelected &&
                                <div onClick={() => {
                                    addAnother(itemSelected)
                                }} className="mt-3 indent">
                                    <VisibilityStatusGuard showInVisibilityStatuses={[]}>
                                        <div className="items-center">
                                            <p className="text-lg text-left underline w-full fore-color">Add Another</p>
                                        </div>
                                    </VisibilityStatusGuard>
                                </div>
                            }

                            <div style={{height: '100px'}}/>

                            <VisibilityStatusGuard showInVisibilityStatuses={[]}>
                                <ProductStickyFooter cartItem={cartItem} setCartItem={setCartItem} closeProduct={close}/>
                            </VisibilityStatusGuard>
                        </>

                    }

                </div>
            }

        </BottomSheet>
    );
}
