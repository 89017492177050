import styled from "styled-components";


interface ActionButtonProps {
    background?: string;
    color?: string;
    labeled?: boolean;
}

export const ActionButton = styled.div<ActionButtonProps>`
  width: 38px;
  height: 38px;
  background-color: ${props => props.background ? props.background : `white`};
  border-radius: 10px;
  margin: 1px 3px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 5px #d3d1d860;
  ${props => props.labeled &&
          `width: unset; padding: 0 15px`
  }
`;

export const ActionButtonLabel = styled.span`
  color: white;
  padding: 0 10px 0 15px;
  font-size: 14pt;
`;


export const ActionButtonTag = styled.div`
  width: 17px;
  aspect-ratio: 1;
  background-color: var(--theme-primary-color);
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -5px;
  right: -5px;
  font-size: 12px;
`;


export const ActionButtonImage = styled.img`
  max-width: 22px;
  max-height: 22px;
  color: yellow;
`;
